import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { menuService } from 'api/menu/menu.api.service';
import { parseAxiosError } from 'utils/errors';

export const getAvailableMenuProducts = createAsyncThunk(
  'available/menuProducts',
  async (vendor: number, { rejectWithValue }) => {
    try {
      return await menuService.getAvailableMenuProducts(vendor);
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(parseAxiosError(error));
    }
  },
);
