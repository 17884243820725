import { CreateStoreMenuRequest } from 'types/menu.type';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status } from 'enums/status';
import {
  getDoorDashStoreMenu,
  updateDoorDashStoreMenu,
} from 'redux/storeMenuCreation/storeMenuCreation.action';

interface CreateStoreMenuRequestState {
  data: CreateStoreMenuRequest;
  status: Status;
  menuCreationStatus: Status;
  apiErrorMessage: string;
}

const initialState: CreateStoreMenuRequestState = {
  data: {
    open_hours: [],
    special_hours: [],
    store: {
      merchant_supplied_id: '',
      provider_type: '',
    },
  },
  status: Status.IDLE,
  menuCreationStatus: Status.IDLE,
  apiErrorMessage: '',
};

const slice = createSlice({
  name: 'StoreMenu',
  initialState,
  reducers: {
    setCreateStoreMenuData: (
      state,
      { payload }: PayloadAction<CreateStoreMenuRequest>,
    ) => {
      state.data = {
        ...payload,
        id: state.data.id,
        store: state.data.store,
        reference: state.data.reference,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDoorDashStoreMenu.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = Status.SUCCESS;
      })
      .addCase(getDoorDashStoreMenu.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addCase(getDoorDashStoreMenu.pending, (state) => {
        state.status = Status.IN_PROGRESS;
      })
      .addCase(updateDoorDashStoreMenu.fulfilled, (state) => {
        state.menuCreationStatus = Status.SUCCESS;
      })
      .addCase(updateDoorDashStoreMenu.rejected, (state, { error }) => {
        state.menuCreationStatus = Status.ERROR;
        state.apiErrorMessage = error.message
          ? error.message
          : 'Error occurred while saving menu, Please again after sometime !';
      })
      .addCase(updateDoorDashStoreMenu.pending, (state) => {
        state.menuCreationStatus = Status.IN_PROGRESS;
      })
      .addDefaultCase((state) => state);
  },
});

export const storeMenuCreationReducer = slice.reducer;
export const { setCreateStoreMenuData } = slice.actions;
