import { StoreFinderResponse } from 'api/stores/types';
import { getPlatformAPIUrl } from 'utils/platform-api-url';
import axios from 'axios';

class StoresApiService {
  async getAllStores(): Promise<StoreFinderResponse> {
    const url = getPlatformAPIUrl();
    const response = await axios.get<StoreFinderResponse>(
      `${url}/stores/store-finder/get-stores?latitude=0&longitude=0`,
    );

    return response.data;
  }
}

const storesService = new StoresApiService();

export { storesService };
