import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const loginContainer: SxProps<Theme> = (theme) => ({
  width: '500px',
  height: '150px',
  borderRadius: '50px',
  boxShadow: theme.shadows[10],
  border: '1px solid grey',
  padding: '30px',
});

export const microsoftLogin: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  top: '20%',
  border: '1px solid grey',
  borderRadius: '50px',
  padding: '10px 0',
  cursor: 'pointer',
  '& img': {
    width: '40px',
    height: '40px',
  },
  '& h1': {
    fontSize: '20px',
    marginLeft: '20px',
  },
};
