import { Box, SxProps, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import React, { FC } from 'react';

export const wrapper: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mt: 5,
  cursor: 'pointer',
  gap: 1.5,
};

interface Props {
  onClick: () => void;
}

export const AdditionalCategoryItem: FC<Props> = ({ onClick }) => (
  <Box sx={wrapper} onClick={onClick}>
    <AddBoxIcon />
    <Typography>Category Item</Typography>
  </Box>
);
