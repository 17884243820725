import {
  CategoriesRequest,
  CreateMenuRequest,
  ItemsRequest,
  MenuAvailabilityRequest,
} from 'types/menu.type';
import { RootState } from 'redux/store';
import { Status } from 'enums/status';

export const menuData = (state: RootState): CreateMenuRequest =>
  state.menuCreation.data;

export const menuHoursData = (
  state: RootState,
): MenuAvailabilityRequest[] | undefined => {
  if (state.menuCreation.data.id) {
    return state.menuCreation.data.open_hours;
  }
  return undefined;
};

export const menuCategoriesData = (state: RootState): CategoriesRequest[] => {
  if (state.menuCreation.data.id && state.menuCreation.data.menu?.categories) {
    return state.menuCreation.data.menu?.categories;
  }
  return [];
};

export const menuItems = (state: RootState): (ItemsRequest | undefined)[] => {
  const items = [];

  if (state.menuCreation.data.id && state.menuCreation.data.menu?.categories) {
    for (
      let i = 0;
      i < state.menuCreation.data.menu.categories.length;
      i += 1
    ) {
      items.push(state.menuCreation.data.menu.categories[i].items);
    }
  }
  return items.flat();
};
export const menuCategoryItems = (
  categoryId: number,
  state: RootState,
): ItemsRequest[] => {
  if (
    state.menuCreation.data.id &&
    state.menuCreation.data.menu?.categories &&
    state.menuCreation.data.menu?.categories[categoryId] &&
    state.menuCreation.data.menu?.categories[categoryId].items
  ) {
    return state.menuCreation.data.menu?.categories[categoryId].items || [];
  }
  return [];
};

export const menuFetchStatus = (state: RootState): Status =>
  state.menuCreation.status;

export const menuCreationStatus = (state: RootState): Status =>
  state.menuCreation.menuCreationStatus;

export const getErrorMessage = (state: RootState): string =>
  state.menuCreation.apiErrorMessage;
