import * as styles from 'components/Header/LoginHeader/styles';
import { Box } from '@mui/material';
import { Image } from 'components/common/Image/Image';
import {
  doorDashLogo,
  headerEdibleLogo,
  uberEatsLogo,
} from 'utils/cloudinaryAssets';
import AddIcon from '@mui/icons-material/Add';
import React, { FC } from 'react';

export const LoginHeader: FC = () => (
  <Box sx={styles.headerContainer}>
    <Box sx={styles.logoContainer}>
      <Image src={headerEdibleLogo} height="42px" width="136px" />
      <AddIcon sx={styles.addIcon} />
      <Image
        src={doorDashLogo}
        height="40px"
        width="40px"
        sx={{ marginTop: '12px' }}
      />
      <AddIcon sx={styles.addIcon} />
      <Image
        src={uberEatsLogo}
        height="40px"
        width="40px"
        sx={{ marginTop: '12px' }}
      />
    </Box>
  </Box>
);
