import * as styles from 'containers/LoginContainer/styles';
import { Box, Typography } from '@mui/material';
import { Image } from 'components/common/Image/Image';
import { microsoftLogo } from 'utils/cloudinaryAssets';
import React, { FC } from 'react';

interface Props {
  loginUser: () => void;
}

export const LoginContainer: FC<Props> = ({ loginUser }) => {
  return (
    <Box sx={styles.loginContainer}>
      <Typography variant="h1" align="center" fontSize="25px" fontWeight="500">
        Third Party Merchant Portal
      </Typography>
      <Box sx={styles.microsoftLogin} onClick={() => loginUser()}>
        <Image src={microsoftLogo} />
        <Typography variant="h1">Sign in with Microsoft</Typography>
      </Box>
    </Box>
  );
};
