import { Status } from 'enums/status';
import { adminVerification } from 'redux/auth/auth.action';
import { createSlice } from '@reduxjs/toolkit';

export type AuthState = {
  data: {
    token: string;
    userAuthorized: boolean;
  };
  status: Status;
};

const initialState: AuthState = {
  data: {
    token: '',
    userAuthorized: false,
  },
  status: Status.IDLE,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adminVerification.fulfilled, (state, { payload }) => {
        state.data.userAuthorized = true;
        state.data.token = payload;
        state.status = Status.SUCCESS;
      })
      .addCase(adminVerification.rejected, (state) => {
        state.data.userAuthorized = false;
        state.status = Status.ERROR;
      })
      .addCase(adminVerification.pending, (state) => {
        state.status = Status.IN_PROGRESS;
      })
      .addDefaultCase((state) => state);
  },
});

export const authReducer = slice.reducer;
