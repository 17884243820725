/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';
import { ResponseError, TranslatableError } from 'types/error.types';

export const parseAxiosError = (error: AxiosError): ResponseError => {
  const { response, message } = error;
  let data: TranslatableError;

  if (typeof response?.data === 'string') {
    data = {
      key: response.data,
      vars: {},
    };
  } else {
    data = response?.data as TranslatableError;
  }

  return {
    data,
    message: message ?? '',
    status: response?.status ?? 500,
  };
};
