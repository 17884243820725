import { AxiosError } from 'axios';
import { CurrentMenuModifiers } from 'api/modifiers/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { modifierService } from 'api/modifiers/modifiers.service';
import { parseAxiosError } from 'utils/errors';

export const getAvailableModifiers = createAsyncThunk(
  'available/modifiers',
  async (_, { rejectWithValue }) => {
    try {
      return await modifierService.getAvailableMenuModifiers();
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const getCurrentMenuModifiers = createAsyncThunk(
  'current/modifiers',
  async (_, { rejectWithValue }) => {
    try {
      return await modifierService.getCurrentMenuModifiers();
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const saveMenuModifiers = createAsyncThunk(
  'save/modifiers',
  async (modifierData: CurrentMenuModifiers[], { rejectWithValue }) => {
    try {
      return await modifierService.saveMenuModifiers(modifierData);
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(parseAxiosError(error));
    }
  },
);
