import {
  CategoriesRequest,
  CreateStoreMenuRequest,
  ItemsRequest,
  MenuAvailabilityRequest,
  StoreRequest,
} from 'types/menu.type';
import { RootState } from 'redux/store';
import { Status } from 'enums/status';

export const storeMenuData = (state: RootState): CreateStoreMenuRequest =>
  state.storeMenu.data;
export const storeData = (state: RootState): StoreRequest =>
  state.storeMenu.data.store;
export const storeMenuHoursData = (
  state: RootState,
): MenuAvailabilityRequest[] | undefined => {
  if (state.storeMenu.data.id) {
    return state.storeMenu.data.open_hours;
  }
  return undefined;
};
export const storeMenuCategoriesData = (
  state: RootState,
): CategoriesRequest[] => {
  if (state.storeMenu.data.id && state.storeMenu.data.menu?.categories) {
    return state.storeMenu.data.menu?.categories;
  }
  return [];
};
export const storeMenuCategoryItems = (
  categoryId: number,
  state: RootState,
): ItemsRequest[] => {
  if (
    state.storeMenu.data.id &&
    state.storeMenu.data.menu?.categories &&
    state.storeMenu.data.menu?.categories[categoryId] &&
    state.storeMenu.data.menu?.categories[categoryId].items
  ) {
    return state.storeMenu.data.menu?.categories[categoryId].items || [];
  }
  return [];
};
export const storeMenuFetchStatus = (state: RootState): Status =>
  state.storeMenu.status;
export const storeMenuCreationStatus = (state: RootState): Status =>
  state.storeMenu.menuCreationStatus;
export const getErrorMessage = (state: RootState): string =>
  state.storeMenu.apiErrorMessage;
