/* eslint-disable no-console */
import * as styles from 'containers/AppContainer/styles';
import { Box } from '@mui/material'; // Import your msalInstance
import { BrowserRouter } from 'react-router-dom';
import { HeaderContainer } from 'containers/HeaderContainer/HeaderContainer';
import { LoginContainer } from 'containers/LoginContainer/LoginContainer';
import { MainContainer } from 'containers/MainContainer/MainContainer';
import { MsalProvider } from '@azure/msal-react';
import { NavigationContainer } from 'containers/NavigationContainer/NavigationContainer';
import { PublicClientApplication } from '@azure/msal-browser';
import { adminVerification } from 'redux/auth/auth.action';
import { useDispatch, useSelector } from 'react-redux';
import { userAuthorized } from 'redux/auth/auth.selectors';
import React, { FC, useEffect, useState } from 'react';

export const AppContainer: FC = () => {
  const dispatch = useDispatch();
  const isAdminAuthenticated = useSelector(userAuthorized);

  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const superAdmins = [
    'mkaplan@netsolace.com',
    'scortner@edible.com',
    'cmcgriff@edible.com',
  ];

  const publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: window.APP_ID,
      redirectUri: window.REDIRECT_URI,
      authority: 'https://login.microsoftonline.com/EdibleArrangements.com',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  });

  const accounts = publicClientApplication.getAllAccounts();

  useEffect(() => {
    if (accounts.length > 0) {
      setIsAuthenticated(true);
    } else {
      publicClientApplication
        .handleRedirectPromise()
        .then((response) => {
          if (response) {
            publicClientApplication.setActiveAccount(response.account);
            setIsAuthenticated(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    if (accounts.length > 0) {
      const isAdmin = superAdmins.includes(accounts[0].username.toLowerCase());
      if (isAdmin === true) {
        setIsSuperAdmin(true);
      }
    }
  }, [accounts]);

  const loginUser = async () => {
    const res = await publicClientApplication.loginPopup({
      scopes: ['user.read'],
      prompt: 'select_account',
    });

    dispatch(adminVerification(res.accessToken));
  };

  return (
    <MsalProvider instance={publicClientApplication}>
      <div>
        {isAuthenticated || isAdminAuthenticated ? (
          <Box
            display="flex"
            height="100%"
            fontFamily="Spartan, Arial, sans-serif"
          >
            <BrowserRouter>
              <NavigationContainer isSuperAdmin={isSuperAdmin} />
              <MainContainer isSuperAdmin={isSuperAdmin} />
            </BrowserRouter>
          </Box>
        ) : (
          <>
            <HeaderContainer />
            <Box sx={styles.loginContainer}>
              <LoginContainer loginUser={loginUser} />
            </Box>
          </>
        )}
      </div>
    </MsalProvider>
  );
};
