export const menuHours = {
  Monday: {
    open: true,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'MON',
  },
  Tuesday: {
    open: true,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'TUE',
  },
  Wednesday: {
    open: true,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'WED',
  },
  Thursday: {
    open: true,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'THU',
  },
  Friday: {
    open: true,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'FRI',
  },
  Saturday: {
    open: true,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'SAT',
  },
  Sunday: {
    open: true,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'SUN',
  },
};

export const menuHoursClosedState = {
  Monday: {
    open: false,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'MON',
  },
  Tuesday: {
    open: false,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'TUE',
  },
  Wednesday: {
    open: false,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'WED',
  },
  Thursday: {
    open: false,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'THU',
  },
  Friday: {
    open: false,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'FRI',
  },
  Saturday: {
    open: false,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'SAT',
  },
  Sunday: {
    open: false,
    startTime: '09:00',
    endTime: '18:00',
    dayIndex: 'SUN',
  },
};
