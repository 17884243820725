import { Box, Typography } from '@mui/material';
import { StoreFinderWidget } from 'api/stores/types';
import { StoreTable } from 'components/MenuManager/StoreTable.tsx/StoreTable';
import React, { FC } from 'react';

interface Props {
  stores: StoreFinderWidget[];
}

export const MenuManager: FC<Props> = ({ stores }) => {
  return (
    <Box width="1000px" margin="0 auto" p={5} overflow="scroll">
      <Typography variant="h2" color="#191919" mb={1}>
        MENU MANAGER
      </Typography>
      <Box ml={3} mb={6}>
        <Typography variant="body3" color="#191919">
          Get started building your MENU by first providing a <br /> name and
          short description of the menu you&apos;re creating.
        </Typography>
      </Box>
      <Box width={1}>
        {stores.length >= 1 && <StoreTable stores={stores} />}
      </Box>
    </Box>
  );
};
