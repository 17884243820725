import { getDoorDashStoreMenu } from 'redux/storeMenuCreation/storeMenuCreation.action';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function useFetchAvailableStoreEdibleMenu(): void {
  const dispatch = useDispatch();
  const { StoreNumber: StoreParam } = useParams<{ StoreNumber: string }>();
  useEffect(() => {
    if (StoreParam) {
      dispatch(getDoorDashStoreMenu(StoreParam));
    }
  }, [StoreParam]);
}
