import { getAvailableModifiers } from 'redux/modifiers/modifiers.action';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export function useFetchAvailableModifiers(): void {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAvailableModifiers());
  }, []);
}
