import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { menuItems } from 'redux/menuCreation/menuCreation.selectors';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import React, { FC } from 'react';

interface Props {
  selectedItems: OptionType[];
  handleSelectionChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: OptionType[],
  ) => void;
}

interface OptionType {
  name: string;
  sku: string;
  img: string;
}

export const ProductSelection: FC<Props> = ({
  selectedItems,
  handleSelectionChange,
}) => {
  const allMenuItems = useSelector(menuItems);

  const allOptions = allMenuItems.map((item) => ({
    name: item?.name || '',
    sku: item?.sku || '',
    img: item?.original_image_url || '',
  }));

  const filteredOptions = allOptions.filter(
    (option) => !selectedItems.some((selected) => selected.sku === option.sku),
  );

  return (
    <Box sx={{ width: 900 }}>
      <Autocomplete
        multiple
        options={filteredOptions}
        getOptionLabel={(option) => option.name}
        value={selectedItems}
        onChange={handleSelectionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Select multiple products"
            placeholder={selectedItems.length ? '' : 'Choose...'}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              avatar={
                <Avatar
                  src={
                    selectedItems.find((item) => item.sku === option.sku)?.img
                  }
                  alt={option.name}
                  style={{ height: '30px', width: '30px' }}
                />
              }
              sx={{ borderRadius: '50px' }}
              variant="outlined"
              label={option.name}
              {...getTagProps({ index })}
              key={option.sku}
            />
          ))
        }
      />
    </Box>
  );
};
