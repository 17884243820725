import { ImageBase } from 'components/common/Image/styles';
import { SxProps, Theme } from '@mui/material';
import { noImageAvailable } from 'utils/cloudinaryAssets';
import React, { FC } from 'react';

type Props = {
  sx?: SxProps<Theme>;
  animationDuration?: number;
  disableTransition?: boolean;
  fallbackImageLoaded: boolean;
  setFallbackImageLoaded: (arg: boolean) => void;
  errorFallback?: string | Omit<React.ReactNode, 'string'>;
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'loading'>;

export const DefaultErrorImage: FC<Props> = ({
  sx,
  errorFallback,
  disableTransition,
  animationDuration = 1000,
  fallbackImageLoaded = false,
  setFallbackImageLoaded,
  ...imgProps
}) => (
  <ImageBase
    src={noImageAvailable}
    sx={sx}
    width={imgProps.width ?? '100%'}
    height={imgProps.height ?? '100%'}
    alt="Image Not Available"
    onLoad={() => setFallbackImageLoaded(true)}
    animation-duration={animationDuration}
    transition={disableTransition ? 0 : 1}
    transition-in={!disableTransition && fallbackImageLoaded ? 1 : 0}
  />
);

DefaultErrorImage.defaultProps = {
  errorFallback: undefined,
  animationDuration: 1000,
  disableTransition: false,
};
