import { Autocomplete, Box, TextField } from '@mui/material';
import { ProductOptions } from 'redux/availableMenuProducts/availableMenuProducts.selectors';
import { sanitize } from 'utils/sanitize';
import FormControl from '@mui/material/FormControl';
import React, { FC } from 'react';

interface Props {
  productOptions: ProductOptions[];
  selectedProduct: number | undefined;
  onSelection: (sku: number) => void;
}

export const SelectProducts: FC<Props> = ({
  productOptions,
  selectedProduct,
  onSelection,
}) => {
  const selectedOption = productOptions.find(
    (x) => x.value === selectedProduct,
  );
  return (
    <FormControl sx={{ m: 1 }}>
      <Autocomplete
        options={productOptions}
        value={selectedOption}
        id="product-select"
        onChange={(e, option) => onSelection(Number(option?.value))}
        sx={{ width: 355, height: 70 }}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box key={option.value} component="li" {...props}>
            {sanitize(option.name)}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Please select a product"
            placeholder={selectedOption ? '' : 'Choose...'}
          />
        )}
      />
    </FormControl>
  );
};
