import {
  AvailableDoorDashModifiers,
  CurrentMenuModifiers,
} from 'api/modifiers/types';
import { RootState } from 'redux/store';
import { Status } from 'enums/status';

export const allAvailableModifiers = (
  state: RootState,
): AvailableDoorDashModifiers[] => state.menuModifiers.data.productData;

export const savedMenuModifiers = (state: RootState): CurrentMenuModifiers[] =>
  state.menuModifiers.data.menuModifiers;

export const selectModifierData = (state: RootState): CurrentMenuModifiers[] =>
  state.menuModifiers.data.modifierData;

export const modifierSaveStatus = (state: RootState): Status =>
  state.menuModifiers.modifierSaveStatus;
