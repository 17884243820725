import { Box } from '@mui/material';
import { StoreTable } from 'components/StoreTable/StoreTable';
import { allStores } from 'redux/stores/stores.selectors';
import { useFetchEdibleStores } from 'containers/StoresContainer/hooks/use-fetch-edible-stores';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

export const StoresContainer: FC = () => {
  useFetchEdibleStores();
  const allEdibleStores = useSelector(allStores);

  return (
    <Box width={1} padding={15} overflow="scroll">
      {allEdibleStores.length >= 1 && <StoreTable stores={allEdibleStores} />}
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export
export default StoresContainer;
