import { CreateMenuRequest } from 'types/menu.type';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status } from 'enums/status';
import {
  addDoorDashEdibleMenu,
  getDoorDashEdibleMenu,
} from 'redux/menuCreation/menuCreation.action';

interface CreateMenuRequestState {
  data: CreateMenuRequest;
  status: Status;
  apiErrorMessage: string;
  menuCreationStatus: Status;
}

const initialState: CreateMenuRequestState = {
  data: {
    open_hours: [],
    special_hours: [],
  },
  status: Status.IDLE,
  menuCreationStatus: Status.IDLE,
  apiErrorMessage: '',
};

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setCreateMenuData: (
      state,
      { payload }: PayloadAction<CreateMenuRequest>,
    ) => {
      state.data = { ...payload, id: state.data.id };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDoorDashEdibleMenu.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = Status.SUCCESS;
      })
      .addCase(getDoorDashEdibleMenu.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addCase(getDoorDashEdibleMenu.pending, (state) => {
        state.status = Status.IN_PROGRESS;
      })
      .addCase(addDoorDashEdibleMenu.fulfilled, (state) => {
        state.menuCreationStatus = Status.SUCCESS;
      })
      .addCase(addDoorDashEdibleMenu.rejected, (state, { error }) => {
        state.menuCreationStatus = Status.ERROR;
        state.apiErrorMessage = error.message
          ? error.message
          : 'Error occurred while saving menu, Please again after sometime !';
      })
      .addCase(addDoorDashEdibleMenu.pending, (state) => {
        state.menuCreationStatus = Status.IN_PROGRESS;
      })
      .addDefaultCase((state) => state);
  },
});

export const menuCreationReducer = slice.reducer;
export const { setCreateMenuData } = slice.actions;
