import { Box, Typography } from '@mui/material';
import { Image } from 'components/common/Image/Image';
import { VendorOptions } from 'types/vendor-options.type';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import React, { FC } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface Props {
  options: VendorOptions[];
  onChange: (event: SelectChangeEvent) => void;
  vendorSelected: string;
}

export const SelectVendor: FC<Props> = ({
  options,
  onChange,
  vendorSelected,
}) => (
  <FormControl sx={{ m: 1, minWidth: 220 }}>
    <Select
      value={vendorSelected}
      onChange={onChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {options.map((option) => (
        <MenuItem key={option.name} value={option.value}>
          <Box display="flex" gap={2}>
            <Image
              src={option.imgUrl}
              alt={option.name}
              height="25px"
              width="25px"
            />
            <Typography>{option.name}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
