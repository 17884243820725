import { allStores } from 'redux/stores/stores.selectors';
import { getAllStores } from 'redux/stores/stores.action';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export function useFetchEdibleStores(): void {
  const dispatch = useDispatch();
  const allEdibleStores = useSelector(allStores);

  useEffect(() => {
    if (allEdibleStores.length === 0) {
      dispatch(getAllStores());
    }
  }, [allEdibleStores]);
}
