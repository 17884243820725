import { Box } from '@mui/material';
import { MenuCreation } from 'components/MenuCreation/MenuCreation';
import { useFetchAvailableEdibleMenu } from 'containers/MenuCreationContainer/hooks/use-fetch-available-edible-menu';
import { useFetchAvailableMenuProducts } from 'containers/MenuCreationContainer/hooks/use-fetch-available-menu-products';
import React, { FC } from 'react';

export const MenuCreationContainer: FC = () => {
  useFetchAvailableMenuProducts();
  useFetchAvailableEdibleMenu();

  return (
    <Box sx={{ borderRight: '3px solid grey', overflow: 'scroll' }} width={1}>
      <MenuCreation />
    </Box>
  );
};
