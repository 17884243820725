import { AdditionalCategoryCreation } from 'components/MenuManager/StoreMenuCreation/StoreMenuForm/CategoryCreation/components/AdditionalCategoryCreation/AdditionalCategoryCreation';
import { Alert, Box, TextField, Typography } from '@mui/material';
import { CategoryCreation } from 'components/MenuManager/StoreMenuCreation/StoreMenuForm/CategoryCreation/CategoryCreation';
import { Menu } from 'types/menu.type';
import { MenuAvailability } from 'types/menu-availability';
import { MenuHours } from 'components/MenuManager/StoreMenuCreation/StoreMenuForm/MenuHours/MenuHours';
import { menuError } from 'containers/AppContainer/styles';
import { storeMenuCategoriesData } from 'redux/storeMenuCreation/storeMenuCreation.selectors';
import { useScrollToTopOnError } from 'components/common/hooks/use-scroll-to-top-on-error';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  form: Menu;
  dayToggle: (arg: string) => void;
  menuAvailability: MenuAvailability;
  setMenuAvailability: (arg: MenuAvailability) => void;
  updateForm: (arg: Partial<Menu>) => void;
  menuAvailabilityReq: () => {
    day_index: string;
    start_time: string;
    end_time: string;
  }[];
  timeChange: (arg1: string, arg2: string, arg3: string) => void;
  setMenuUpdated: (arg: boolean) => void;
  errorMessage: string;
}

export const StoreMenuForm: FC<Props> = ({
  form,
  dayToggle,
  updateForm,
  timeChange,
  menuAvailability,
  setMenuAvailability,
  menuAvailabilityReq,
  setMenuUpdated,
  errorMessage,
}) => {
  useScrollToTopOnError(errorMessage);
  const menuCategories = useSelector(storeMenuCategoriesData);
  const [categoryMenuCount, setCategoryMenuCount] = useState<number>(1);

  useEffect(() => {
    if (menuCategories.length > 0) {
      setCategoryMenuCount(menuCategories.length);
    }
  }, [menuCategories]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    enabled?: boolean,
  ) => {
    const { name, value } = event.target;

    if (enabled !== undefined) {
      updateForm({ [name]: enabled });
    } else {
      updateForm({ [name]: value });
    }

    setMenuUpdated(true);
  };

  const categoryMenus = Array(categoryMenuCount)
    .fill(0)
    .map((_, index) => (
      <CategoryCreation
        // eslint-disable-next-line react/no-array-index-key
        key={`${index}_menuCategory`}
        id={index}
        form={form}
        menuAvailabilityReq={menuAvailabilityReq}
      />
    ));

  const updateCategoryMenuCount = () => {
    setCategoryMenuCount(categoryMenuCount + 1);
  };

  return (
    <Box width="1000px" margin="0 auto" p={5}>
      <Typography variant="h2" color="#191919" mb={1}>
        STORE MENU CREATION
      </Typography>
      <Box ml={3} mb={6}>
        <Typography variant="body3" color="#191919">
          Get started building your Store MENU by first providing a <br /> name
          and short description of the menu you&apos;re creating.
        </Typography>
        {errorMessage && (
          <Alert sx={menuError} severity="error">
            {errorMessage}
          </Alert>
        )}
      </Box>
      <Box display="flex" justifyContent="end" mr={3}>
        <Typography>Enabled</Typography>
        <input
          name="enabled"
          type="checkbox"
          checked={form.enabled}
          onChange={(e) => handleInputChange(e, !form.enabled)}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-around">
          <TextField
            onChange={handleInputChange}
            label="Name"
            name="name"
            variant="standard"
            required
            sx={{ width: '300px' }}
            value={form.name}
          />
          <TextField
            onChange={handleInputChange}
            label="Description"
            name="description"
            variant="standard"
            helperText="Optional"
            sx={{
              width: '300px',
              '& p': {
                textAlign: 'right',
              },
            }}
            value={form.description}
          />
        </Box>
        <Box alignSelf="center">
          <MenuHours
            dayToggle={dayToggle}
            timeChange={timeChange}
            menuAvailability={menuAvailability}
            setMenuAvailability={setMenuAvailability}
          />
        </Box>
        {categoryMenus}
      </Box>
      <AdditionalCategoryCreation onClick={updateCategoryMenuCount} />
    </Box>
  );
};
