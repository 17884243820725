/* eslint-disable jsx-a11y/label-has-associated-control */
import * as styles from 'components/MenuCreation/MenuForm/MenuHours/styles';
import { Box, Typography } from '@mui/material';
import { MenuAvailability } from 'types/menu-availability';
import { SelectHours } from 'components/MenuManager/StoreMenuCreation/StoreMenuForm/SelectHours/SelectHours';
import { mapMenuHoursToMenuAvailability } from 'components/MenuCreation/MenuForm/MenuHours/mapper/mapMenuHoursToAvailability';
import { storeMenuHoursData } from 'redux/storeMenuCreation/storeMenuCreation.selectors';
import { useSelector } from 'react-redux';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import React, { FC, useEffect } from 'react';

interface Props {
  dayToggle: (arg: string) => void;
  timeChange: (arg1: string, arg2: string, arg3: string) => void;
  menuAvailability: MenuAvailability;
  setMenuAvailability: (arg: MenuAvailability) => void;
}

export const MenuHours: FC<Props> = ({
  dayToggle,
  timeChange,
  menuAvailability,
  setMenuAvailability,
}) => {
  const menuHours = useSelector(storeMenuHoursData);
  useEffect(() => {
    if (menuHours) {
      setMenuAvailability(mapMenuHoursToMenuAvailability(menuHours));
    }
  }, [menuHours]);

  return (
    <Box sx={styles.menuHoursWrapper} p={4} mt={5}>
      <Typography variant="h3" pb={2} color="#191919">
        MENU AVAILABILITY
      </Typography>
      <Box ml={3} mb={6}>
        <Typography variant="body3" color="#191919">
          Now choose what day of the week and/or time of the day you would like
          this menu to be available.
        </Typography>
      </Box>
      {Object.entries(menuAvailability).map(([day, details]) => (
        <Box key={day}>
          <Box display="flex" justifyContent="space-between">
            <Box sx={styles.dayText}>
              <Typography variant="subtitle1">{day}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <label htmlFor={`${day}-start-time`}>
                <SelectHours
                  startTime
                  day={day}
                  open={details.open}
                  onChange={timeChange}
                  timeSelected={details.startTime}
                />
              </label>
              <HorizontalRuleIcon />
              <label htmlFor={`${day}-end-time`}>
                <SelectHours
                  day={day}
                  startTime={false}
                  open={details.open}
                  onChange={timeChange}
                  timeSelected={details.endTime}
                />
              </label>
            </Box>
            <Box sx={styles.checkBox}>
              <input
                type="checkbox"
                checked={!details.open}
                onChange={() => dayToggle(day)}
              />
              <Typography variant="body1">Closed</Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
