/* eslint-disable react/no-array-index-key */
import * as styles from 'components/MenuCreation/MenuForm/CategoryCreation/styles';
import { AdditionalCategoryItem } from 'components/MenuManager/StoreMenuCreation/StoreMenuForm/CategoryItems/components/AdditonalCategoryItem/AdditonalCategoryItem';
import { Box, TextField, Typography } from '@mui/material';
import { Category } from 'types/category.type';
import { CategoryItems } from 'components/MenuManager/StoreMenuCreation/StoreMenuForm/CategoryItems/CategoryItems';
import { CategoryProducts } from 'types/category-items.type';
import { MapMenuItemsToMenuCategoryItems } from 'components/MenuCreation/MenuForm/CategoryCreation/mapper/mapMenuItemToMenuCategoryItem';
import { Menu } from 'types/menu.type';
import { RootState } from 'redux/store';
import { setCreateStoreMenuData } from 'redux/storeMenuCreation/storeMenuCreation.slice';
import {
  storeData,
  storeMenuCategoriesData,
  storeMenuCategoryItems,
  storeMenuData,
} from 'redux/storeMenuCreation/storeMenuCreation.selectors';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  id: number;
  form: Menu;
  menuAvailabilityReq: () => {
    day_index: string;
    start_time: string;
    end_time: string;
  }[];
}

export const CategoryCreation: FC<Props> = ({
  id,
  form,
  menuAvailabilityReq,
}) => {
  const dispatch = useDispatch();
  const menuState = useSelector(storeMenuData);
  const storeInfo = useSelector(storeData);
  const menuCategories = useSelector(storeMenuCategoriesData);
  const currentMenuCategoryItems = useSelector((state: RootState) =>
    storeMenuCategoryItems(id, state),
  );
  const [categoryItemCount, setCategoryItemCount] = useState<number>(1);
  const [products, setProducts] = useState<CategoryProducts[]>([]);
  const [categoryUpdated, setCategoryUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (currentMenuCategoryItems.length > 0) {
      setCategoryItemCount(currentMenuCategoryItems.length);
      setProducts(MapMenuItemsToMenuCategoryItems(currentMenuCategoryItems));
    }
  }, [currentMenuCategoryItems]);

  const initialCategoryData = {
    name: '',
    description: '',
    enabled: true,
  };
  const [category, setCategory] = useState<Category>(initialCategoryData);

  useEffect(() => {
    if (menuCategories.length > 0 && menuCategories[id]) {
      const categoryData = {
        name: menuCategories[id].name || '',
        description: menuCategories[id].subtitle || '',
        enabled:
          menuCategories[id].active !== undefined
            ? menuCategories[id].active || false
            : true,
      };

      setCategory(categoryData);
    }
  }, [menuCategories]);

  useEffect(() => {
    if (categoryUpdated && category.name !== '') {
      const currentCategory = {
        name: category.name,
        subtitle: category.description,
        merchant_supplied_id: (id + 1).toString(),
        active: category.enabled,
        items:
          products.length > 0
            ? products.map((product) => ({
                name: product.name,
                description: product.description,
                merchant_supplied_id: product.merchant_supplied_id,
                active: product.enabled,
                price: product.price,
                base_price: product.base_price,
                original_image_url: product.original_image_url,
                sku: product.sku,
              }))
            : [],
      };
      const categories = [...(menuState.menu?.categories || [])];
      if (categories.length > 0) {
        const index = categories.findIndex(
          (x) =>
            x.merchant_supplied_id &&
            x.merchant_supplied_id === currentCategory.merchant_supplied_id,
        );
        if (index !== -1) {
          categories[index] = currentCategory;
        } else {
          categories.push(currentCategory);
        }
      } else {
        categories.push(currentCategory);
      }
      const menu = {
        open_hours: menuAvailabilityReq(),
        special_hours: [],
        menu: {
          id: form.id,
          name: form.name,
          subtitle: form.description,
          merchant_supplied_id: '1',
          active: form.enabled,
          categories,
        },
        store: storeInfo,
      };

      dispatch(setCreateStoreMenuData(menu));
      setCategoryUpdated(false);
    }
  }, [categoryUpdated]);

  const updateProductsForm = (
    updatedValues: Partial<CategoryProducts>,
    idToUpdate: number,
  ) => {
    const index = products.findIndex((obj) => obj.id === idToUpdate);

    if (index !== -1) {
      const newData = [...products];
      newData[index] = { ...newData[index], ...updatedValues };
      setProducts(newData);
    }
    setCategoryUpdated(true);
  };

  const updateCategoryForm = (updatedValues: Partial<Category>) => {
    setCategory((prevForm) => ({
      ...prevForm,
      ...updatedValues,
    }));
  };

  const handleSelectedProduct = (item: CategoryProducts) => {
    const index = products.findIndex((obj) => obj.id === item.id);
    if (index !== -1) {
      const newData = [...products];
      newData[index] = item;
      setProducts(newData);
    } else {
      const newData = [...products, item];
      setProducts(newData);
    }
    setCategoryUpdated(true);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    enabled?: boolean,
  ) => {
    const { name, value } = event.target;

    if (enabled !== undefined) {
      updateCategoryForm({ [name]: enabled });
    } else {
      updateCategoryForm({ [name]: value });
    }
    setCategoryUpdated(true);
  };

  const categoryItems = Array(categoryItemCount)
    .fill(0)
    .map((_, index) => (
      <>
        {index !== 0 && (
          <Box key={`${index}_menuDivider`} sx={styles.divider} />
        )}
        <CategoryItems
          key={`${index}_menuCategoryItems`}
          id={index}
          categoryItemId={id}
          updateProductsForm={updateProductsForm}
          handleSelectedProduct={handleSelectedProduct}
        />
      </>
    ));

  const updateCategoryItemCount = () => {
    setCategoryItemCount(categoryItemCount + 1);
  };

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h3" pb={2} color="#191919">
        MENU CATEGORY
      </Typography>
      <Box ml={3} mb={6}>
        <Typography variant="body3" color="#191919">
          Next, lets create a MENU category by providing a name and short
          description of your category ie. Birthday, Anniversary, etc.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="end">
        <Typography>Enabled</Typography>
        <input
          name="enabled"
          type="checkbox"
          checked={category.enabled}
          onChange={(e) => handleInputChange(e, !category.enabled)}
        />
      </Box>
      <Box display="flex" justifyContent="space-around">
        <TextField
          onChange={handleInputChange}
          label="Name"
          name="name"
          variant="standard"
          required
          sx={{ width: '300px' }}
          value={category.name}
        />
        <TextField
          onChange={handleInputChange}
          label="Description"
          name="description"
          variant="standard"
          helperText="Optional"
          sx={styles.descriptionText}
          value={category.description}
        />
      </Box>
      <Typography variant="h3" pb={4} color="#191919" mt={8}>
        CATEGORY PRODUCTS
      </Typography>
      <Box ml={3} mb={6}>
        <Typography variant="body3" color="#191919">
          Finally, add relevant products to your category by first selecting a
          product to add from the dropdown and editing it as you wish.
        </Typography>
      </Box>
      {categoryItems}
      <AdditionalCategoryItem onClick={updateCategoryItemCount} />
    </Box>
  );
};
