export const getTPAPIUrl = (): string => {
  const url = window.TP_API_URL;
  return `${url}`;
};

export const getTPAPIKey = (): string => {
  const key = window.TP_API_KEY;
  return `${key}`;
};

export const getTPToken = (): string => {
  const token = window.TP_TOKEN;
  return `${token}`;
};
