import { Box } from '@mui/material';
import { MenuModifiers } from 'components/MenuModifiers/MenuModifiers';
import { useFetchAvailableEdibleMenu } from 'containers/MenuCreationContainer/hooks/use-fetch-available-edible-menu';
import { useFetchAvailableModifiers } from 'containers/MenuModifiersContainer/hooks/use-fetch-available-modifiers';
import { useFetchMenuModifiers } from 'containers/MenuModifiersContainer/hooks/use-fetch-menu-modifiers';
import React, { FC } from 'react';

export const MenuModifiersContainer: FC = () => {
  useFetchAvailableModifiers();
  useFetchAvailableEdibleMenu();
  useFetchMenuModifiers();

  return (
    <Box overflow="scroll" width={1}>
      <MenuModifiers />
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export
export default MenuModifiersContainer;
