import { Box, Button, Dialog, Typography } from '@mui/material';
import { navigate } from 'utils/navigate';
import React, { FC } from 'react';

interface Props {
  opened?: boolean;
  message?: string;
}

export const DialogBox: FC<Props> = ({ opened, message }) => {
  return (
    <Dialog open={Boolean(opened)}>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Typography
          variant="body2"
          style={{ textAlign: 'center', margin: '20px 50px' }}
        >
          {message}
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate('/door-dash/menu-manager')}
          style={{ textAlign: 'center', margin: '20px 50px' }}
        >
          Ok
        </Button>
      </Box>
    </Dialog>
  );
};
