import { getTPAPIKey, getTPAPIUrl } from 'utils/tp-api-url';
import axios from 'axios';

class AuthApiService {
  async adminVerification(token: string): Promise<string> {
    const header = {
      'Ocp-Apim-Subscription-Key': getTPAPIKey(),
    };
    const response = await axios.get<string>(
      `${getTPAPIUrl()}/admin/verification?accessToken=${token}`,
      { headers: header },
    );

    return response.data;
  }
}

const authService = new AuthApiService();

export { authService };
