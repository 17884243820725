import { Box, TextField, Typography } from '@mui/material';
import { ModifierSelection } from 'components/MenuModifiers/MenuForm/ModifierSelection/ModifierSelection';
import { Option } from 'types/modifier-options.type';
import { allAvailableModifiers } from 'redux/modifiers/modifiers.selectors';
import { menuModifierOption } from 'initial-state/menu-modifier-option';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  modOption: Option;
  updateSelectedOption: (modifier: Option, originalOption?: string) => void;
  updateOption: (productId: string, updatedValues: Partial<Option>) => void;
}

export const ModifierOption: FC<Props> = ({
  modOption,
  updateOption,
  updateSelectedOption,
}) => {
  const availableModifierOptions = useSelector(allAvailableModifiers);
  const [modifierSelected, setModifierSelected] = useState<boolean>(false);
  const [modifierOption, setModifierOption] =
    useState<Option>(menuModifierOption);

  useEffect(() => {
    setModifierOption(modOption);
    setModifierSelected(true);
  }, []);

  const selectedModifierHandler = (productId: number) => {
    const modifier = availableModifierOptions.find(
      (p) => p.productId === productId,
    );

    if (modifier) {
      const modifierToSet = {
        merchant_supplied_id: String(modifier.productId),
        name: modifier.addonOptionName,
        active: true,
        price: Number(modifier.salePrice),
        base_price: Number(modifier.salePrice),
        original_image_url: modifier.imageUrl,
      };
      updateSelectedOption(modifierToSet, modOption.merchant_supplied_id);
      setModifierOption(modifierToSet);
      setModifierSelected(true);
    }
  };

  const updateModifierOption = (updatedValues: Partial<Option>) => {
    setModifierOption((prevForm) => ({
      ...prevForm,
      ...updatedValues,
    }));

    updateOption(modOption.merchant_supplied_id, updatedValues);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    enabled?: boolean,
  ) => {
    const { name, value } = event.target;

    if (enabled !== undefined) {
      updateModifierOption({ [name]: enabled });
    } else {
      updateModifierOption({ [name]: value });
    }
  };

  return (
    <Box margin="50px auto">
      <Box display="flex" justifyContent="end">
        <Typography>Enabled</Typography>
        <input
          name="active"
          type="checkbox"
          checked={modifierOption.active}
          onChange={(e) => handleInputChange(e, !modifierOption.active)}
        />
      </Box>
      <ModifierSelection
        availableModifierOptions={availableModifierOptions}
        onSelection={selectedModifierHandler}
        selectedModifierOption={modifierOption}
      />
      <Box mt={3} display={modifierSelected ? 'block' : 'none'}>
        <Box display="flex" justifyContent="space-around">
          <TextField
            onChange={handleInputChange}
            label="Name"
            name="name"
            variant="standard"
            required
            sx={{ width: '300px' }}
            value={modifierOption.name}
          />
          <TextField
            onChange={handleInputChange}
            label="SKU"
            name="merchant_supplied_id"
            variant="standard"
            disabled
            required
            sx={{ width: '300px' }}
            value={modifierOption.merchant_supplied_id}
          />
        </Box>
        <Box mt={3} display="flex" justifyContent="space-around">
          <TextField
            onChange={handleInputChange}
            label="Price"
            name="price"
            variant="standard"
            required
            sx={{ width: '300px' }}
            value={modifierOption.price}
          />
          <TextField
            onChange={handleInputChange}
            label="Image"
            name="original_image_url"
            variant="standard"
            required
            sx={{ width: '300px' }}
            value={modifierOption.original_image_url}
          />
        </Box>
      </Box>
    </Box>
  );
};
