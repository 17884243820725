import * as styles from 'containers/NavigationContainer/styles';
import { Box } from '@mui/material';
import { NavigationMenu } from 'components/NavigationMenu/NavigationMenu';
import React, { FC } from 'react';

interface Props {
  isSuperAdmin: boolean;
}

export const NavigationContainer: FC<Props> = ({ isSuperAdmin }) => (
  <Box sx={styles.navigationContainer}>
    <NavigationMenu isSuperAdmin={isSuperAdmin} />
  </Box>
);
