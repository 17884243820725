import { AdditionalModifierCreation } from 'components/MenuModifiers/MenuForm/AdditionalModifierCreation/AdditionalModifierCreation';
import { Alert, Box, Typography } from '@mui/material';
import { ModifierCreation } from 'components/MenuModifiers/MenuForm/ModifierCreation/ModifierCreation';
import { menuError } from 'containers/AppContainer/styles';
import { savedMenuModifiers } from 'redux/modifiers/modifiers.selectors';
import { useScrollToTopOnError } from 'components/common/hooks/use-scroll-to-top-on-error';
import { useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  errorMessage: string;
}

export const ModifierForm: FC<Props> = ({ errorMessage }) => {
  useScrollToTopOnError(errorMessage);
  const menuModifiers = useSelector(savedMenuModifiers);
  const [modifierCount, setModifierCount] = useState<number>(1);

  useEffect(() => {
    if (menuModifiers.length > 0) {
      setModifierCount(menuModifiers.length);
    }
  }, [menuModifiers]);

  const modifierComponent = () => {
    const component = [];
    for (let i = 0; i < modifierCount; i += 1) {
      component.push(
        menuModifiers[i] ? (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{menuModifiers[i].name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ModifierCreation
                key={menuModifiers[i].id}
                modifier={menuModifiers[i]}
              />
            </AccordionDetails>
          </Accordion>
        ) : (
          <ModifierCreation
            key={i}
            modifier={{
              sku: [],
              name: '',
              merchant_supplied_id: '',
              min_num_options: 0,
              max_num_options: 0,
              options: [],
            }}
          />
        ),
      );
    }
    return component;
  };

  const updateModifierCount = () => {
    setModifierCount(modifierCount + 1);
  };

  return (
    <Box width="1000px" margin="0 auto" p={5}>
      <Typography variant="h2" color="#191919" mb={1}>
        MENU MODIFIERS
      </Typography>
      <Box ml={3} mb={6}>
        {errorMessage && (
          <Alert sx={menuError} severity="error">
            {errorMessage}
          </Alert>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        {modifierComponent()}
      </Box>
      <AdditionalModifierCreation onClick={updateModifierCount} />
    </Box>
  );
};
