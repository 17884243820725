/* eslint-disable @typescript-eslint/no-unused-vars */
import 'core-js/features/string/ends-with';
import 'core-js/features/string/match-all';
import 'core-js/features/string/repeat';
import 'es6-shim';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'styles/App.css';
import { AppContainer } from 'containers/AppContainer/AppContainer';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import { persistStore } from 'redux-persist';
import { plugin } from 'utils/telemetry/app-insights';
import { root } from 'utils/root';
import { store } from 'redux/store';
import React from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <Provider store={store}>
    <AppInsightsContext.Provider value={plugin}>
      <React.StrictMode>
        <StyledEngineProvider injectFirst>
          <AppContainer />
        </StyledEngineProvider>
      </React.StrictMode>
    </AppInsightsContext.Provider>
  </Provider>,
  root,
);
