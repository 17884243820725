import { AvailableDoorDashProduct } from 'api/menu/types';
import {
  CreateMenuRequest,
  CreateStoreMenuRequest,
  DoorDashMenuStatus,
} from 'types/menu.type';
import { getPlatformAPIUrl } from 'utils/platform-api-url';
import { getTPAPIKey, getTPAPIUrl, getTPToken } from 'utils/tp-api-url';
import axios from 'axios';

class MenuApiService {
  async getAvailableMenuProducts(
    vendor: number,
  ): Promise<AvailableDoorDashProduct[]> {
    const url = getPlatformAPIUrl();
    const response = await axios.get<AvailableDoorDashProduct[]>(
      `${url}/arrangements/external-vendor-arrangements/${vendor}`,
    );

    return response.data;
  }

  async createMenu(data: CreateMenuRequest): Promise<string> {
    const header = {
      'Ocp-Apim-Subscription-Key': getTPAPIKey(),
    };
    const response = await axios.post<string>(
      `${getTPAPIUrl()}/doordash/menu/addEdibleMenu`,
      data,
      { headers: header },
    );

    return response.data;
  }

  async getMenu(): Promise<CreateMenuRequest> {
    const header = {
      'Ocp-Apim-Subscription-Key': getTPAPIKey(),
    };
    const response = await axios.get<CreateMenuRequest>(
      `${getTPAPIUrl()}/doordash/menu/getEdibleMenu`,
      { headers: header },
    );

    return response.data;
  }

  // here
  async getStoreMenu(storeNumber: string): Promise<CreateStoreMenuRequest> {
    const response = await axios.get<CreateStoreMenuRequest>(
      `${getTPAPIUrl()}/doordash/menu/getStoreMenu/${storeNumber}`,
      {
        headers: {
          Authorization: `Bearer ${getTPToken()}`,
          'Ocp-Apim-Subscription-Key': getTPAPIKey(),
        },
      },
    );

    return response.data;
  }

  async updateMenu(data: CreateStoreMenuRequest): Promise<DoorDashMenuStatus> {
    const header = {
      'Ocp-Apim-Subscription-Key': getTPAPIKey(),
    };
    const response = await axios.post<DoorDashMenuStatus>(
      `${getTPAPIUrl()}/doordash/menu/update`,
      data,
      { headers: header },
    );

    return response.data;
  }
}

const menuService = new MenuApiService();

export { menuService };
