import { AvailableDoorDashProduct } from 'api/menu/types';
import { RootState } from 'redux/store';
import { Status } from 'enums/status';

export type ProductOptions = {
  value: number;
  name: string;
};

export const allAvailableMenuProducts = (
  state: RootState,
): AvailableDoorDashProduct[] => state.availableMenuProducts.data.productData;

export const availableMenuProductStatus = (state: RootState): Status =>
  state.availableMenuProducts.status;

export const availableProductNamesSku = (
  state: RootState,
): ProductOptions[] => {
  const productsData = state.availableMenuProducts.data.productData.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.sku === value.sku),
  );
  return productsData.map(({ arrangementName, productId, sizeName }) => ({
    value: productId,
    name: `${arrangementName} - ${sizeName}`,
  }));
};
