import { CategoryProducts } from 'types/category-items.type';
import { ItemsRequest } from 'types/menu.type';
import { categoryProducts } from 'initial-state/category-items';

export const MapMenuItemToMenuCategoryItem = (
  id: number,
  item?: ItemsRequest,
): CategoryProducts => {
  if (item) {
    const categoryProduct: CategoryProducts = {
      id,
      name: item.name,
      price: item.price,
      enabled: item.active !== undefined ? item.active : true,
      base_price: item.base_price || 0,
      description: item.description || '',
      original_image_url: item.original_image_url || '',
      merchant_supplied_id: item.merchant_supplied_id,
      sku: item.sku || '',
    };
    return categoryProduct;
  }
  return categoryProducts;
};

export const MapMenuItemsToMenuCategoryItems = (
  items?: ItemsRequest[],
): CategoryProducts[] => {
  const categoryItems: CategoryProducts[] = [];
  if (items) {
    items.forEach((item, index) => {
      const categoryProduct: CategoryProducts = {
        id: index,
        name: item.name,
        price: item.price,
        enabled: item.active !== undefined ? item.active : true,
        base_price: item.base_price || 0,
        description: item.description || '',
        original_image_url: item.original_image_url || '',
        merchant_supplied_id: item.merchant_supplied_id,
        sku: item.sku || '',
      };

      categoryItems.push(categoryProduct);
    });
  }
  return categoryItems;
};
