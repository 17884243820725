import { MenuAvailability } from 'types/menu-availability';
import { MenuAvailabilityRequest } from 'types/menu.type';

export const mapMenuHoursToMenuAvailability = (
  openHours: MenuAvailabilityRequest[],
): MenuAvailability => {
  const menuAvailability = {
    Monday: {
      open: false,
      startTime: '09:00',
      endTime: '18:00',
      dayIndex: 'MON',
    },
    Tuesday: {
      open: false,
      startTime: '09:00',
      endTime: '18:00',
      dayIndex: 'TUE',
    },
    Wednesday: {
      open: false,
      startTime: '09:00',
      endTime: '18:00',
      dayIndex: 'WED',
    },
    Thursday: {
      open: false,
      startTime: '09:00',
      endTime: '18:00',
      dayIndex: 'THU',
    },
    Friday: {
      open: false,
      startTime: '09:00',
      endTime: '18:00',
      dayIndex: 'FRI',
    },
    Saturday: {
      open: false,
      startTime: '09:00',
      endTime: '18:00',
      dayIndex: 'SAT',
    },
    Sunday: {
      open: false,
      startTime: '09:00',
      endTime: '18:00',
      dayIndex: 'SUN',
    },
  };
  openHours.forEach((x) => {
    Object.values(menuAvailability).forEach((e) => {
      if (e.dayIndex === x.day_index) {
        e.open = true;
        e.startTime = x.start_time || '09:00';
        e.endTime = x.end_time || '18:00';
      }
    });
  });

  return menuAvailability;
};
