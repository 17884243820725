import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

export const doorDashMenu = [
  {
    id: 0,
    name: 'Store Menu Manager',
    url: '/door-dash/menu-manager',
    Icon: MenuBookTwoToneIcon,
  },
];

export const doorDashAdminMenu = [
  {
    id: 0,
    name: 'Store Menu Manager',
    url: '/door-dash/menu-manager',
    Icon: MenuBookTwoToneIcon,
  },
  {
    id: 1,
    name: 'Menu Creation',
    url: '/door-dash/menu-creation',
    Icon: MenuBookTwoToneIcon,
  },
  {
    id: 2,
    name: 'Menu Modifiers',
    url: '/door-dash/menu-modifiers',
    Icon: MenuBookTwoToneIcon,
  },
];
