import * as styles from 'components/MenuCreation/MenuForm/MenuHours/styles';
import { Box, Typography } from '@mui/material';
import { timeOfDay } from 'components/MenuCreation/MenuForm/SelectHours/utils/time-of-day';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import React, { FC } from 'react';
import Select from '@mui/material/Select';

interface Props {
  day: string;
  open: boolean;
  startTime: boolean;
  timeSelected: string;
  onChange: (day: string, field: string, value: string) => void;
}

export const SelectHours: FC<Props> = ({
  day,
  open,
  onChange,
  startTime,
  timeSelected,
}) => (
  <FormControl sx={{ m: 1 }}>
    <Select
      value={timeSelected}
      onChange={(e) =>
        onChange(day, startTime ? 'startTime' : 'endTime', e.target.value)
      }
      variant="filled"
      inputProps={{ 'aria-label': 'Without label' }}
      sx={styles.hourSelect}
      disabled={!open}
    >
      {timeOfDay.map((time) => (
        <MenuItem key={time.twentyFourHourTime} value={time.twentyFourHourTime}>
          <Box display="flex" gap={2}>
            <Typography>{!open ? 'Closed' : time.readableTime}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
