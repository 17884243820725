import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist/es/constants';
import { authReducer } from 'redux/auth/auth.slice';
import { availableMenuProductsReducer } from 'redux/availableMenuProducts/availableMenuProducts.slice';
import { availableModifiersReducer } from 'redux/modifiers/modifiers.slice';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { isInProduction } from 'utils/environment';
import { menuCreationReducer } from 'redux/menuCreation/menuCreation.slice';
import { storeMenuCreationReducer } from 'redux/storeMenuCreation/storeMenuCreation.slice';
import { storesReducer } from 'redux/stores/stores.slice';

const rootReducers = combineReducers({
  auth: authReducer,
  availableMenuProducts: availableMenuProductsReducer,
  stores: storesReducer,
  menuCreation: menuCreationReducer,
  menuModifiers: availableModifiersReducer,
  storeMenu: storeMenuCreationReducer,
});

export const store = configureStore({
  reducer: rootReducers,
  devTools: !isInProduction(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
