import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';

export const loginContainer: SxProps<Theme> = (theme) => ({
  width: '100%',
  height: '92%',
  display: 'flex',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
});

export const menuError: SxProps = {
  fontFamily: fonts.spartan,
  fontWeight: '600',
  fontSize: '15px',
  mt: 3,
  color: colors.primaryRed,
};
