/* eslint-disable @typescript-eslint/no-unused-vars */
import { Backdrop } from 'components/common/Backdrop/Backdrop';
import { Box, Button, Typography } from '@mui/material';
import { CategoriesRequest, Menu } from 'types/menu.type';
import { DialogBox } from 'components/common/DialogBox/DialogBox';
import { MenuAvailability } from 'types/menu-availability';
import { MenuForm } from 'components/MenuCreation/MenuForm/MenuForm';
import { Status } from 'enums/status';
import { addDoorDashEdibleMenu } from 'redux/menuCreation/menuCreation.action';
import {
  getErrorMessage,
  menuCreationStatus,
  menuData,
  menuFetchStatus,
} from 'redux/menuCreation/menuCreation.selectors';
import { menu } from 'initial-state/menu.state';
import { menuHours } from 'initial-state/menu-hours.state';
import { schema } from 'components/MenuCreation/schema';
import { setCreateMenuData } from 'redux/menuCreation/menuCreation.slice';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';

export const MenuCreation: FC = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const menuToAdd = useSelector(menuData);
  const isProcessing = useSelector(menuCreationStatus) === Status.IN_PROGRESS;
  const menuCreated = useSelector(menuCreationStatus) === Status.SUCCESS;
  const isMenuLoading = useSelector(menuFetchStatus) === Status.IN_PROGRESS;
  const [menuCategories, setMenuCategories] = useState<CategoriesRequest[]>([]);
  const [form, setForm] = useState<Menu>(menu);
  const [menuAvailability, setMenuAvailability] =
    useState<MenuAvailability>(menuHours);
  const [menuUpdated, setMenuUpdated] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Add Menu');

  const apiErrorMessage = useSelector(getErrorMessage);
  useEffect(() => {
    setErrorMessage(apiErrorMessage);
  }, [apiErrorMessage]);

  useEffect(() => {
    if (menuToAdd.menu) {
      setForm({
        name: menuToAdd.menu?.name,
        description: menuToAdd.menu.subtitle || '',
        enabled:
          menuToAdd.menu.active !== undefined ? menuToAdd.menu.active : true,
      });
      if (menuToAdd.id) {
        setButtonText('Update Menu');
      }
      if (menuToAdd.menu.categories) {
        setMenuCategories(menuToAdd.menu.categories);
      }
    }
  }, [menuToAdd]);

  const updateForm = (updatedValues: Partial<Menu>) => {
    setForm((prevForm) => ({
      ...prevForm,
      ...updatedValues,
    }));
  };

  const handleDayToggle = (day: string) => {
    setMenuAvailability({
      ...menuAvailability,
      [day]: {
        ...menuAvailability[day],
        open: !menuAvailability[day].open,
      },
    });

    setMenuUpdated(true);
  };

  const handleTimeChange = (day: string, field: string, value: string) => {
    setMenuAvailability({
      ...menuAvailability,
      [day]: {
        ...menuAvailability[day],
        [field]: value,
      },
    });

    setMenuUpdated(true);
  };

  const menuAvailabilityReq = () =>
    Object.entries(menuAvailability)
      .filter(([_, value]) => value.open)
      .map(([_, value]) => ({
        day_index: value.dayIndex,
        start_time: value.startTime,
        end_time: value.endTime,
      }));

  useEffect(() => {
    if (menuUpdated) {
      const updatedMenu = {
        open_hours: menuAvailabilityReq(),
        special_hours: [],
        menu: {
          name: form.name,
          subtitle: form.description,
          merchant_supplied_id: '1',
          active: form.enabled,
          categories: menuCategories,
        },
      };
      dispatch(setCreateMenuData(updatedMenu));
      setMenuUpdated(false);
    }
  }, [menuUpdated]);

  const addMenu = () => {
    schema
      .validate(menuToAdd)
      .then((x) => {
        if (x) {
          dispatch(addDoorDashEdibleMenu(menuToAdd));
        }
      })
      .catch((e) => {
        setErrorMessage((e as Error).message);
      });
  };
  return (
    <>
      <DialogBox
        opened={menuCreated}
        message="Edible Door Dash Menu created successfully !"
      />
      <Backdrop opened={isProcessing || isMenuLoading} />
      <Box mt={5} display="flex" flexDirection="column">
        <MenuForm
          form={form}
          updateForm={updateForm}
          dayToggle={handleDayToggle}
          timeChange={handleTimeChange}
          menuAvailability={menuAvailability}
          setMenuAvailability={setMenuAvailability}
          menuAvailabilityReq={menuAvailabilityReq}
          setMenuUpdated={setMenuUpdated}
          errorMessage={errorMessage}
        />
        <Button
          variant="contained"
          onClick={() => addMenu()}
          style={{ margin: '0 auto', width: 300 }}
        >
          {buttonText}
        </Button>
        <Typography style={{ margin: '0 auto' }} variant="body2">
          © 2009-
          {new Date().getFullYear()} Edible
        </Typography>
      </Box>
    </>
  );
};
