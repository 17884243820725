import { allAvailableMenuProducts } from 'redux/availableMenuProducts/availableMenuProducts.selectors';
import { getAvailableMenuProducts } from 'redux/availableMenuProducts/availableMenuProducts.action';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export function useFetchAvailableMenuProducts(): void {
  const dispatch = useDispatch();
  const allAvailableProducts = useSelector(allAvailableMenuProducts);

  useEffect(() => {
    if (allAvailableProducts.length === 0) {
      dispatch(getAvailableMenuProducts(1));
    }
  }, [allAvailableProducts]);
}
