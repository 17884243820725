import { Box, SelectChangeEvent } from '@mui/material';
import { DoorDashMenu } from 'components/NavigationMenu/components/DoorDashMenu/DoorDashMenu';
import { SelectVendor } from 'components/NavigationMenu/components/SelectVendor/SelectVendor';
import {
  doorDashAdminMenu,
  doorDashMenu,
} from 'components/NavigationMenu/utils/door-dash-menu';
import { useNavigate } from 'react-router-dom';
import { vendors } from 'components/NavigationMenu/utils/vendors';
import React, { FC, useState } from 'react';

interface Props {
  isSuperAdmin: boolean;
}

export const NavigationMenu: FC<Props> = ({ isSuperAdmin }) => {
  const navigate = useNavigate();
  const [vendorSelected, setVendorSelected] = useState<string>('0');

  const handleChange = (event: SelectChangeEvent) => {
    setVendorSelected(event.target.value.toString());
  };

  const handleClick = (url: string) => {
    navigate(url);
  };

  return (
    <Box>
      <SelectVendor
        options={vendors}
        vendorSelected={vendorSelected}
        onChange={handleChange}
      />
      {vendorSelected === '0' &&
        isSuperAdmin &&
        doorDashAdminMenu.map((menuItem) => (
          <DoorDashMenu
            key={menuItem.id}
            menu={menuItem}
            handleClick={handleClick}
          >
            <menuItem.Icon />
          </DoorDashMenu>
        ))}
      {vendorSelected === '0' &&
        !isSuperAdmin &&
        doorDashMenu.map((menuItem) => (
          <DoorDashMenu
            key={menuItem.id}
            menu={menuItem}
            handleClick={handleClick}
          >
            <menuItem.Icon />
          </DoorDashMenu>
        ))}
    </Box>
  );
};
