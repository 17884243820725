export const categoryProducts = {
  id: 0,
  name: '',
  price: 0,
  enabled: true,
  base_price: 0,
  description: '',
  original_image_url: '',
  merchant_supplied_id: '',
  sku: '',
};
