import { SrcSet } from 'components/common/Image/Image';
import { isIE } from 'utils/is-internet-explorer';
import { useIsUpLg } from 'utils/hidden/use-is-up-lg';
import { useIsUpMd } from 'utils/hidden/hide-up-md';
import { useIsUpXs } from 'utils/hidden/use-only-xs';

export const useGetSrc = (src: string, srcSet?: SrcSet): string | undefined => {
  const isUpXs = useIsUpXs();
  const isUpSm = useIsUpMd();
  const isUpMd = useIsUpLg();

  if (isIE && srcSet) {
    const { xs, sm, md } = srcSet;

    if (isUpMd) {
      return md?.url ?? sm?.url ?? xs.url;
    }

    if (isUpSm) {
      return sm?.url ?? xs.url;
    }

    if (isUpXs) {
      return xs.url;
    }
  }

  return src;
};
