import { Vendor } from 'enums/vendor';
import { doorDashLogo, uberEatsLogo } from 'utils/cloudinaryAssets';
import React from 'react';

export const vendors = [
  {
    name: 'DoorDash',
    imgUrl: doorDashLogo,
    value: Vendor.DOOR_DASH,
    Icon: React.Component,
  },
  {
    name: 'Uber Eats',
    imgUrl: uberEatsLogo,
    value: Vendor.UBER_EATS,
    Icon: React.Component,
  },
];
