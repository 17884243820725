import { AuthState } from 'redux/auth/auth.slice';
import { RootState } from 'redux/store';
import { Status } from 'enums/status';

export const userAuthorized = (state: RootState): boolean =>
  state.auth.data.userAuthorized;

export const userAuthState = (state: RootState): AuthState => state.auth;

export const userAuthStatus = (state: RootState): Status => state.auth.status;
