import { AvailableDoorDashProduct } from 'api/menu/types';
import { Status } from 'enums/status';
import { createSlice } from '@reduxjs/toolkit';
import { getAvailableMenuProducts } from 'redux/availableMenuProducts/availableMenuProducts.action';

export type AvailableMenuState = {
  data: {
    productData: AvailableDoorDashProduct[];
  };
  status: Status;
};

const initialState: AvailableMenuState = {
  data: {
    productData: [],
  },
  status: Status.IDLE,
};

const slice = createSlice({
  name: 'availableMenuProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableMenuProducts.fulfilled, (state, { payload }) => {
        state.data.productData = payload;
        state.status = Status.SUCCESS;
      })
      .addCase(getAvailableMenuProducts.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addCase(getAvailableMenuProducts.pending, (state) => {
        state.status = Status.IN_PROGRESS;
      })
      .addDefaultCase((state) => state);
  },
});

export const availableMenuProductsReducer = slice.reducer;
