import { Box } from '@mui/material';
import { StoreMenuCreation } from 'components/MenuManager/StoreMenuCreation/StoreMenuCreation';
import { useFetchAvailableMenuProducts } from 'containers/MenuCreationContainer/hooks/use-fetch-available-menu-products';
import { useFetchAvailableStoreEdibleMenu } from 'containers/StoreMenuCreationContainer/hooks/use-fetch-available-store-edible-menu';
import React, { FC } from 'react';

export const StoreMenuCreationContainer: FC = () => {
  useFetchAvailableMenuProducts();
  useFetchAvailableStoreEdibleMenu();

  return (
    <Box sx={{ borderRight: '3px solid grey', overflow: 'scroll' }} width={1}>
      <StoreMenuCreation />
    </Box>
  );
};
