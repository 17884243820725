import { getCurrentMenuModifiers } from 'redux/modifiers/modifiers.action';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export function useFetchMenuModifiers(): void {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentMenuModifiers());
  }, []);
}
