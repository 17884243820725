import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const headerContainer: SxProps = {
  height: '85px',
  display: 'flex',
  paddingBottom: '5px',
  backgroundSize: '100% 5px',
  justifyContent: 'space-between',
};

export const logoContainer: SxProps = {
  display: 'flex',
  padding: '15px 15px 0 0',
  justifyContent: 'flex-end',
  marginLeft: '15px',
};

export const addIcon: SxProps<Theme> = (theme) => ({
  fontSize: '22px',
  margin: 'auto 10px',
  marginTop: '20px',
  color: theme.palette.primary.main,
});
