import {
  AvailableDoorDashModifiers,
  CurrentMenuModifiers,
} from 'api/modifiers/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status } from 'enums/status';
import {
  getAvailableModifiers,
  getCurrentMenuModifiers,
  saveMenuModifiers,
} from 'redux/modifiers/modifiers.action';

export type AvailableModifiersState = {
  data: {
    productData: AvailableDoorDashModifiers[];
    menuModifiers: CurrentMenuModifiers[];
    modifierData: CurrentMenuModifiers[];
  };
  modifierSaveStatus: Status;
  status: Status;
};

const initialState: AvailableModifiersState = {
  data: {
    productData: [],
    menuModifiers: [],
    modifierData: [],
  },
  modifierSaveStatus: Status.IDLE,
  status: Status.IDLE,
};

const slice = createSlice({
  name: 'availableModifiers',
  initialState,
  reducers: {
    setModifierData: (
      state,
      { payload }: PayloadAction<CurrentMenuModifiers>,
    ) => {
      const index = state.data.modifierData.findIndex(
        (item) => item.id === payload.id,
      );
      if (index !== -1) {
        state.data.modifierData[index] = {
          ...state.data.modifierData[index],
          ...payload,
        };
      } else {
        state.data.modifierData.push(payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableModifiers.fulfilled, (state, { payload }) => {
        state.data.productData = payload;
        state.status = Status.SUCCESS;
      })
      .addCase(getAvailableModifiers.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addCase(getAvailableModifiers.pending, (state) => {
        state.status = Status.IN_PROGRESS;
      })
      .addCase(getCurrentMenuModifiers.fulfilled, (state, { payload }) => {
        state.data.modifierData = payload;
        state.data.menuModifiers = payload;
        state.status = Status.SUCCESS;
      })
      .addCase(getCurrentMenuModifiers.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addCase(getCurrentMenuModifiers.pending, (state) => {
        state.status = Status.IN_PROGRESS;
      })
      .addCase(saveMenuModifiers.fulfilled, (state) => {
        state.modifierSaveStatus = Status.SUCCESS;
      })
      .addCase(saveMenuModifiers.rejected, (state) => {
        state.modifierSaveStatus = Status.ERROR;
      })
      .addCase(saveMenuModifiers.pending, (state) => {
        state.modifierSaveStatus = Status.IN_PROGRESS;
      })
      .addDefaultCase((state) => state);
  },
});

export const availableModifiersReducer = slice.reducer;
export const { setModifierData } = slice.actions;
