export const timeOfDay = [
  {
    readableTime: '12:00 AM',
    twentyFourHourTime: '00:00',
  },
  {
    readableTime: '12:15 AM',
    twentyFourHourTime: '00:15',
  },
  {
    readableTime: '12:30 AM',
    twentyFourHourTime: '00:30',
  },
  {
    readableTime: '12:45 AM',
    twentyFourHourTime: '00:45',
  },
  {
    readableTime: '01:00 AM',
    twentyFourHourTime: '01:00',
  },
  {
    readableTime: '01:15 AM',
    twentyFourHourTime: '01:15',
  },
  {
    readableTime: '01:30 AM',
    twentyFourHourTime: '01:30',
  },
  {
    readableTime: '01:45 AM',
    twentyFourHourTime: '01:45',
  },
  {
    readableTime: '02:00 AM',
    twentyFourHourTime: '02:00',
  },
  {
    readableTime: '02:15 AM',
    twentyFourHourTime: '02:15',
  },
  {
    readableTime: '02:30 AM',
    twentyFourHourTime: '02:30',
  },
  {
    readableTime: '02:45 AM',
    twentyFourHourTime: '02:45',
  },
  {
    readableTime: '03:00 AM',
    twentyFourHourTime: '03:00',
  },
  {
    readableTime: '03:15 AM',
    twentyFourHourTime: '03:15',
  },
  {
    readableTime: '03:30 AM',
    twentyFourHourTime: '03:30',
  },
  {
    readableTime: '03:45 AM',
    twentyFourHourTime: '03:45',
  },
  {
    readableTime: '04:00 AM',
    twentyFourHourTime: '04:00',
  },
  {
    readableTime: '04:15 AM',
    twentyFourHourTime: '04:15',
  },
  {
    readableTime: '04:30 AM',
    twentyFourHourTime: '04:30',
  },
  {
    readableTime: '04:45 AM',
    twentyFourHourTime: '04:45',
  },
  {
    readableTime: '05:00 AM',
    twentyFourHourTime: '05:00',
  },
  {
    readableTime: '05:15 AM',
    twentyFourHourTime: '05:15',
  },
  {
    readableTime: '05:30 AM',
    twentyFourHourTime: '05:30',
  },
  {
    readableTime: '05:45 AM',
    twentyFourHourTime: '05:45',
  },
  {
    readableTime: '06:00 AM',
    twentyFourHourTime: '06:00',
  },
  {
    readableTime: '06:15 AM',
    twentyFourHourTime: '06:15',
  },
  {
    readableTime: '06:30 AM',
    twentyFourHourTime: '06:30',
  },
  {
    readableTime: '06:45 AM',
    twentyFourHourTime: '06:45',
  },
  {
    readableTime: '07:00 AM',
    twentyFourHourTime: '07:00',
  },
  {
    readableTime: '07:15 AM',
    twentyFourHourTime: '07:15',
  },
  {
    readableTime: '07:30 AM',
    twentyFourHourTime: '07:30',
  },
  {
    readableTime: '07:45 AM',
    twentyFourHourTime: '07:45',
  },
  {
    readableTime: '08:00 AM',
    twentyFourHourTime: '08:00',
  },
  {
    readableTime: '08:15 AM',
    twentyFourHourTime: '08:15',
  },
  {
    readableTime: '08:30 AM',
    twentyFourHourTime: '08:30',
  },
  {
    readableTime: '08:45 AM',
    twentyFourHourTime: '08:45',
  },
  {
    readableTime: '09:00 AM',
    twentyFourHourTime: '09:00',
  },
  {
    readableTime: '09:15 AM',
    twentyFourHourTime: '09:15',
  },
  {
    readableTime: '09:30 AM',
    twentyFourHourTime: '09:30',
  },
  {
    readableTime: '09:45 AM',
    twentyFourHourTime: '09:45',
  },
  {
    readableTime: '10:00 AM',
    twentyFourHourTime: '10:00',
  },
  {
    readableTime: '10:15 AM',
    twentyFourHourTime: '10:15',
  },
  {
    readableTime: '10:30 AM',
    twentyFourHourTime: '10:30',
  },
  {
    readableTime: '10:45 AM',
    twentyFourHourTime: '10:45',
  },
  {
    readableTime: '11:00 AM',
    twentyFourHourTime: '11:00',
  },
  {
    readableTime: '11:15 AM',
    twentyFourHourTime: '11:15',
  },
  {
    readableTime: '11:30 AM',
    twentyFourHourTime: '11:30',
  },
  {
    readableTime: '11:45 AM',
    twentyFourHourTime: '11:45',
  },
  {
    readableTime: '12:00 PM',
    twentyFourHourTime: '12:00',
  },
  {
    readableTime: '12:15 PM',
    twentyFourHourTime: '12:15',
  },
  {
    readableTime: '12:30 PM',
    twentyFourHourTime: '12:30',
  },
  {
    readableTime: '12:45 PM',
    twentyFourHourTime: '12:45',
  },
  {
    readableTime: '01:00 PM',
    twentyFourHourTime: '13:00',
  },
  {
    readableTime: '01:15 PM',
    twentyFourHourTime: '13:15',
  },
  {
    readableTime: '01:30 PM',
    twentyFourHourTime: '13:30',
  },
  {
    readableTime: '01:45 PM',
    twentyFourHourTime: '13:45',
  },
  {
    readableTime: '02:00 PM',
    twentyFourHourTime: '14:00',
  },
  {
    readableTime: '02:15 PM',
    twentyFourHourTime: '14:15',
  },
  {
    readableTime: '02:30 PM',
    twentyFourHourTime: '14:30',
  },
  {
    readableTime: '02:45 PM',
    twentyFourHourTime: '14:45',
  },
  {
    readableTime: '03:00 PM',
    twentyFourHourTime: '15:00',
  },
  {
    readableTime: '03:15 PM',
    twentyFourHourTime: '15:15',
  },
  {
    readableTime: '03:30 PM',
    twentyFourHourTime: '15:30',
  },
  {
    readableTime: '03:45 PM',
    twentyFourHourTime: '15:45',
  },
  {
    readableTime: '04:00 PM',
    twentyFourHourTime: '16:00',
  },
  {
    readableTime: '04:15 PM',
    twentyFourHourTime: '16:15',
  },
  {
    readableTime: '04:30 PM',
    twentyFourHourTime: '16:30',
  },
  {
    readableTime: '04:45 PM',
    twentyFourHourTime: '16:45',
  },
  {
    readableTime: '05:00 PM',
    twentyFourHourTime: '17:00',
  },
  {
    readableTime: '05:15 PM',
    twentyFourHourTime: '17:15',
  },
  {
    readableTime: '05:30 PM',
    twentyFourHourTime: '17:30',
  },
  {
    readableTime: '05:45 PM',
    twentyFourHourTime: '17:45',
  },
  {
    readableTime: '06:00 PM',
    twentyFourHourTime: '18:00',
  },
  {
    readableTime: '06:15 PM',
    twentyFourHourTime: '18:15',
  },
  {
    readableTime: '06:30 PM',
    twentyFourHourTime: '18:30',
  },
  {
    readableTime: '06:45 PM',
    twentyFourHourTime: '18:45',
  },
  {
    readableTime: '07:00 PM',
    twentyFourHourTime: '19:00',
  },
  {
    readableTime: '07:15 PM',
    twentyFourHourTime: '19:15',
  },
  {
    readableTime: '07:30 PM',
    twentyFourHourTime: '19:30',
  },
  {
    readableTime: '07:45 PM',
    twentyFourHourTime: '19:45',
  },
  {
    readableTime: '08:00 PM',
    twentyFourHourTime: '20:00',
  },
  {
    readableTime: '08:15 PM',
    twentyFourHourTime: '20:15',
  },
  {
    readableTime: '08:30 PM',
    twentyFourHourTime: '20:30',
  },
  {
    readableTime: '08:45 PM',
    twentyFourHourTime: '20:45',
  },
  {
    readableTime: '09:00 PM',
    twentyFourHourTime: '21:00',
  },
  {
    readableTime: '09:15 PM',
    twentyFourHourTime: '21:15',
  },
  {
    readableTime: '09:30 PM',
    twentyFourHourTime: '21:30',
  },
  {
    readableTime: '09:45 PM',
    twentyFourHourTime: '21:45',
  },
  {
    readableTime: '10:00 PM',
    twentyFourHourTime: '22:00',
  },
  {
    readableTime: '10:15 PM',
    twentyFourHourTime: '22:15',
  },
  {
    readableTime: '10:30 PM',
    twentyFourHourTime: '22:30',
  },
  {
    readableTime: '10:45 PM',
    twentyFourHourTime: '22:45',
  },
  {
    readableTime: '11:00 PM',
    twentyFourHourTime: '23:00',
  },
  {
    readableTime: '11:15 PM',
    twentyFourHourTime: '23:15',
  },
  {
    readableTime: '11:30 PM',
    twentyFourHourTime: '23:30',
  },
  {
    readableTime: '11:45 PM',
    twentyFourHourTime: '23:45',
  },
  {
    readableTime: '11:59 PM',
    twentyFourHourTime: '23:59',
  },
];
