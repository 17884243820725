import { Box, SxProps, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import React, { FC } from 'react';

export const wrapper: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mt: 5,
  cursor: 'pointer',
  gap: 1.5,
};

interface Props {
  onClick: () => void;
}

export const AdditionalModifierOption: FC<Props> = ({ onClick }) => {
  return (
    <Box sx={wrapper} onClick={onClick}>
      <AddBoxIcon />
      <Typography>Modifier Option</Typography>
    </Box>
  );
};
