import { Box, TextField, Typography } from '@mui/material';
import { CategoryProducts } from 'types/category-items.type';
import { MapMenuItemToMenuCategoryItem } from 'components/MenuCreation/MenuForm/CategoryCreation/mapper/mapMenuItemToMenuCategoryItem';
import { RootState } from 'redux/store';
import { SelectProducts } from 'components/MenuManager/StoreMenuCreation/StoreMenuForm/CategoryItems/components/SelectProducts/SelectProducts';
import {
  allAvailableMenuProducts,
  availableProductNamesSku,
} from 'redux/availableMenuProducts/availableMenuProducts.selectors';
import { categoryProducts } from 'initial-state/category-items';
import { sanitize } from 'utils/sanitize';
import { storeMenuCategoryItems } from 'redux/storeMenuCreation/storeMenuCreation.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  id: number;
  categoryItemId: number;
  handleSelectedProduct: (product: CategoryProducts) => void;
  updateProductsForm: (
    values: Partial<CategoryProducts>,
    idToUpdate: number,
  ) => void;
}

export const CategoryItems: FC<Props> = ({
  id,
  categoryItemId,
  updateProductsForm,
  handleSelectedProduct,
}) => {
  const allAvailableProducts = useSelector(allAvailableMenuProducts);
  const productSelectOptions = useSelector(availableProductNamesSku);
  const currentMenuCategoryItems = useSelector((state: RootState) =>
    storeMenuCategoryItems(categoryItemId, state),
  );
  const [selectedProduct, setSelectedProduct] =
    useState<CategoryProducts>(categoryProducts);

  useEffect(() => {
    if (currentMenuCategoryItems.length > 0) {
      setSelectedProduct(
        MapMenuItemToMenuCategoryItem(id, currentMenuCategoryItems[id]),
      );
    }
  }, [currentMenuCategoryItems]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    enabled?: boolean,
  ) => {
    const { name, value } = event.target;

    if (enabled !== undefined) {
      setSelectedProduct((prevForm) => ({
        ...prevForm,
        ...{ [name]: enabled },
      }));
      updateProductsForm({ [name]: enabled }, id);
    } else {
      setSelectedProduct((prevForm) => ({
        ...prevForm,
        ...{ [name]: value },
      }));
      updateProductsForm({ [name]: value }, id);
    }
  };

  const selectedProductHandler = (productId: number) => {
    const product = allAvailableProducts.find((p) => p.productId === productId);

    if (product) {
      const productToSet = {
        id,
        name: sanitize(product.arrangementName),
        price: product.price,
        enabled: selectedProduct.enabled,
        base_price: product.regularPrice,
        description: product.description,
        original_image_url: product.imageUrl,
        merchant_supplied_id: String(product.productId),
        sku: String(product.sku),
      };
      setSelectedProduct(productToSet);
      handleSelectedProduct(productToSet);
    }
  };

  return (
    <Box margin="0 auto">
      <Box
        display={selectedProduct.name !== '' ? 'flex' : 'none'}
        justifyContent="end"
      >
        <Typography>Enabled</Typography>
        <input
          name="enabled"
          type="checkbox"
          checked={selectedProduct.enabled}
          onChange={(e) => handleInputChange(e, !selectedProduct.enabled)}
        />
      </Box>
      <Box display="flex" justifyContent="space-around">
        <SelectProducts
          productOptions={productSelectOptions}
          selectedProduct={Number(selectedProduct.merchant_supplied_id)}
          onSelection={selectedProductHandler}
        />
      </Box>
      <Box display={selectedProduct.name !== '' ? 'inline' : 'none'}>
        <Box display="flex" justifyContent="space-around">
          <TextField
            onChange={handleInputChange}
            label="SKU"
            name="sku"
            value={selectedProduct.sku}
            variant="standard"
            disabled
            sx={{ width: '140px' }}
          />
          <TextField
            onChange={handleInputChange}
            label="Name"
            name="name"
            value={selectedProduct.name}
            variant="standard"
            sx={{ width: '300px' }}
          />
          <TextField
            onChange={handleInputChange}
            label="Image URL"
            value={selectedProduct.original_image_url}
            name="original_image_url"
            variant="standard"
            sx={{ width: '300px' }}
          />
        </Box>
        <Box display="flex" justifyContent="space-around" mt={3}>
          <TextField
            onChange={handleInputChange}
            label="Product ID"
            name="merchant_supplied_id"
            value={selectedProduct.merchant_supplied_id}
            variant="standard"
            disabled
            sx={{ width: '140px' }}
          />
          <TextField
            onChange={handleInputChange}
            label=" Delivery Price"
            name="price"
            value={selectedProduct.price}
            variant="standard"
            sx={{ width: '140px' }}
          />
          <TextField
            onChange={handleInputChange}
            label="Pickup Price"
            name="base_price"
            value={selectedProduct.base_price}
            variant="standard"
            sx={{ width: '140px' }}
          />
        </Box>
        <Box mt={4} pl={6} pr={6}>
          <TextField
            id="filled-multiline-static"
            label="Description"
            value={selectedProduct.description}
            name="description"
            multiline
            onChange={handleInputChange}
            rows={4}
            variant="filled"
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  );
};
