import { SxProps } from '@mui/material';

export const wrapper: SxProps = {
  border: '1px solid rgb(231, 231, 231)',
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 8px',
  borderRadius: '8px',
  width: '900px',
  margin: '0 auto',
  p: 4,
  mt: 5,
};

export const descriptionText: SxProps = {
  width: '300px',
  '& p': {
    textAlign: 'right',
  },
};

export const divider: SxProps = {
  width: 1,
  height: '2px',
  backgroundColor: '#191919',
  mt: 5,
  mb: 5,
};
