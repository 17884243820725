import * as yup from 'yup';

export const schema = yup.object({
  store: yup.object({
    provider_type: yup
      .string()
      .required('Door Dash Store Provider is required'),
    merchant_supplied_id: yup.string().required('store number is required'),
  }),
  menu: yup.object({
    name: yup.string().required('Menu name is required'),
    categories: yup
      .array(
        yup.object({
          name: yup.string().required('Menu category name is required'),
          items: yup
            .array(
              yup.object({
                name: yup.string().required('Menu item name is required'),
                merchant_supplied_id: yup
                  .string()
                  .required('Menu item sku is required'),
                price: yup.number().required('Menu item price is required'),
                base_price: yup
                  .number()
                  .required('Menu item base price is required'),
                original_image_url: yup
                  .string()
                  .required('Menu item image url is required'),
                description: yup.string().notRequired(),
              }),
            )
            .min(1, 'Atleast one menu item is required'),
        }),
      )
      .min(1, 'Atleast one menu category is required'),
  }),
  open_hours: yup
    .array(
      yup.object({
        day_index: yup.string().required('select the menu hours day'),
        start_time: yup.string().required('select the menu hours start time'),
        end_time: yup.string().required('select the menu hours end time'),
      }),
    )
    .min(1, 'Menu hours are required'),
});
