import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

type Menu = {
  id: number;
  name: string;
  url: string;
};

interface Props {
  menu: Menu;
  handleClick: (arg: string) => void;
}

export const DoorDashMenu: FC<Props> = ({ menu, children, handleClick }) => {
  return (
    <Box
      key={menu.id}
      onClick={() => handleClick(menu.url)}
      p={2}
      sx={{ cursor: 'pointer', gap: 2, display: 'flex' }}
    >
      {children}
      <Typography>{menu.name}</Typography>
    </Box>
  );
};
