import * as styles from 'components/common/Image/styles';
import { Box, CircularProgress, SxProps, Theme, useTheme } from '@mui/material';
import { ConstructedErrorImage } from 'components/common/Image/ConstructedErrorImage';
import { DefaultErrorImage } from 'components/common/Image/DefaultErrorImage';
import { ImageBase } from 'components/common/Image/styles';
import { getSrcSet } from 'components/common/Image/utils/src-set';
import { useGetSrc } from 'components/common/Image/use-get-src';
import { useInView } from 'react-intersection-observer';
import React, { FC, useRef, useState } from 'react';

type SrcSetItem = {
  url: string;
  width: number;
};

export type SrcSet = {
  xs: SrcSetItem;
  sm?: SrcSetItem;
  md?: SrcSetItem;
};

type Props = {
  src: string;
  srcSet?: SrcSet;
  alt?: string;
  lazy?: boolean;
  disableError?: boolean;
  disableSpinner?: boolean;
  observerMargins?: string;
  square?: boolean;
  loading?: React.ReactNode;
  animationDuration?: number;
  disableTransition?: boolean;
  sx?: SxProps<Theme>;
  iconContainerStyles?: { [key: string]: string };
  errorFallback?: string | Omit<React.ReactNode, 'string'>;
  dataTest?: string;
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'loading' | 'srcSet'>;

export const Image: FC<Props> = ({
  src,
  srcSet,
  alt,
  lazy,
  sx,
  loading,
  disableError,
  errorFallback,
  disableSpinner,
  observerMargins,
  square = false,
  animationDuration = 1000,
  disableTransition,
  iconContainerStyles,
  dataTest,
  ...imgProps
}) => {
  const theme = useTheme();

  const image = useRef<HTMLImageElement>(null);
  const [imageError, setImageError] = useState<boolean>(!src?.length);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [brokenErrorImage, setBrokenErrorImage] = useState<boolean>(false);
  const [fallbackImageLoaded, setFallbackImageLoaded] =
    useState<boolean>(false);
  const imageSrc = useGetSrc(src, srcSet);
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: observerMargins ?? '0px',
  });

  const handleLoadImage = () => {
    setImageError(false);
    setImageLoaded(true);
  };

  const handleImageError = () => {
    if (src) {
      setImageError(true);
    }
  };

  const fallbackImageError = () => {
    setBrokenErrorImage(true);
    setFallbackImageLoaded(true);
  };

  const defaultErrorImage = (
    <DefaultErrorImage
      sx={sx}
      errorFallback={errorFallback}
      animationDuration={animationDuration}
      disableTransition={disableTransition}
      fallbackImageLoaded={fallbackImageLoaded}
      setFallbackImageLoaded={setFallbackImageLoaded}
      {...imgProps}
    />
  );

  const constructedErrorImage = (
    <ConstructedErrorImage
      sx={sx}
      errorFallback={errorFallback}
      animationDuration={animationDuration}
      disableTransition={disableTransition}
      fallbackImageError={fallbackImageError}
      fallbackImageLoaded={fallbackImageLoaded}
      setFallbackImageLoaded={setFallbackImageLoaded}
      alt={alt}
      {...imgProps}
    />
  );

  const show = !lazy || inView;

  const displayBrokenImg = !disableError && imageError && !brokenErrorImage;

  return (
    <Box ref={ref} sx={{ ...styles.root, ...(square ? styles.square : {}) }}>
      {show && (
        <>
          {src && !imageError && (
            <ImageBase
              data-test={dataTest}
              src={imageSrc}
              alt={alt}
              ref={image}
              animation-duration={animationDuration}
              transition={disableTransition ? 0 : 1}
              transition-in={!disableTransition && imageLoaded ? 1 : 0}
              broken={displayBrokenImg ? 1 : 0}
              sx={sx}
              width={imgProps.width ?? '100%'}
              onLoad={() => handleLoadImage()}
              onError={() => handleImageError()}
              height={imgProps.height ?? '100%'}
              {...imgProps}
              {...getSrcSet(srcSet, theme.breakpoints)}
            />
          )}
          <Box sx={{ ...styles.iconContainer, ...iconContainerStyles }}>
            {!disableSpinner && !imageLoaded && !imageError && loading}
          </Box>
          {!disableError && imageError && brokenErrorImage && defaultErrorImage}
          {!disableError &&
            imageError &&
            !brokenErrorImage &&
            constructedErrorImage}
        </>
      )}
    </Box>
  );
};

Image.defaultProps = {
  alt: '',
  lazy: false,
  sx: undefined,
  disableError: false,
  disableSpinner: false,
  animationDuration: 1000,
  errorFallback: undefined,
  disableTransition: false,
  observerMargins: undefined,
  iconContainerStyles: undefined,
  loading: <CircularProgress size={48} />,
};
