import { AxiosError } from 'axios';
import { CreateMenuRequest } from 'types/menu.type';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { menuService } from 'api/menu/menu.api.service';
import { parseAxiosError } from 'utils/errors';

export const getDoorDashEdibleMenu = createAsyncThunk(
  '/doordash/menu/getEdibleMenu',
  async (_, { rejectWithValue }) => {
    try {
      const edibleMenu = await menuService.getMenu();
      return edibleMenu;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const addDoorDashEdibleMenu = createAsyncThunk(
  'doordash/menu/addEdibleMenu',
  async (menuParams: CreateMenuRequest, { rejectWithValue }) => {
    try {
      const edibleMenu = await menuService.createMenu(menuParams);
      return edibleMenu;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(parseAxiosError(error));
    }
  },
);
