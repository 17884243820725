import { getDoorDashEdibleMenu } from 'redux/menuCreation/menuCreation.action';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export function useFetchAvailableEdibleMenu(): void {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDoorDashEdibleMenu());
  }, []);
}
