import { AxiosError } from 'axios';
import { authService } from 'api/auth/auth.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

export const adminVerification = createAsyncThunk(
  'auth/adminVerification',
  async (token: string, { rejectWithValue }) => {
    try {
      const authData = await authService.adminVerification(token);
      return authData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(parseAxiosError(error));
    }
  },
);
