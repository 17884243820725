import * as styles from 'components/Header/LoginHeader/styles';
import { Box } from '@mui/material';
import { Header } from 'components/Header/Header';
import React, { FC } from 'react';

export const HeaderContainer: FC = () => (
  <Box sx={styles.headerContainer}>
    <Header />
  </Box>
);
