import { Image } from 'components/common/Image/Image';
import { LinearProgress, Backdrop as MuiBackdrop } from '@mui/material';
import { animatedLogo } from 'utils/cloudinaryAssets';
import { progress, root } from 'components/common/Backdrop/styles';
import React, { FC } from 'react';

interface Props {
  opened?: boolean;
  displayLogo?: boolean;
}

export const Backdrop: FC<Props> = ({ opened, displayLogo = true }) => (
  <MuiBackdrop sx={root} open={Boolean(opened)}>
    <LinearProgress color="primary" variant="indeterminate" sx={progress} />
    <Image
      src={animatedLogo}
      alt="Animated Edible Arrangements Logo"
      sx={{
        mx: 'auto',
        borderRadius: '50%',
        border: '5px solid red',
        width: ['175px', '200px', '200px'],
        height: ['175px', '200px', '200px'],
        zIndex: '9999',
        display: displayLogo ? 'inline' : 'none',
      }}
      lazy
    />
  </MuiBackdrop>
);
