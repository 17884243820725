import { DefaultErrorImage } from 'components/common/Image/DefaultErrorImage';
import { ImageBase } from 'components/common/Image/styles';
import { SxProps, Theme } from '@mui/material';
import React, { FC } from 'react';

type Props = {
  sx?: SxProps<Theme>;
  animationDuration?: number;
  disableTransition?: boolean;
  fallbackImageLoaded: boolean;
  fallbackImageError: () => void;
  setFallbackImageLoaded: (arg: boolean) => void;
  errorFallback?: string | Omit<React.ReactNode, 'string'>;
  alt?: string;
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'loading'>;

export const ConstructedErrorImage: FC<Props> = ({
  sx,
  errorFallback,
  disableTransition,
  animationDuration = 1000,
  fallbackImageError,
  fallbackImageLoaded = false,
  setFallbackImageLoaded,
  alt,
  ...imgProps
}) => {
  if (!errorFallback) {
    return (
      <DefaultErrorImage
        sx={sx}
        errorFallback={errorFallback}
        animationDuration={animationDuration}
        disableTransition={disableTransition}
        fallbackImageLoaded={fallbackImageLoaded}
        setFallbackImageLoaded={setFallbackImageLoaded}
        {...imgProps}
      />
    );
  }

  return (
    <>
      {typeof errorFallback === 'string' ? (
        <ImageBase
          src={errorFallback}
          sx={sx}
          width={imgProps.width ?? '100%'}
          height={imgProps.height ?? '100%'}
          onError={() => fallbackImageError()}
          alt={alt || 'Image Not Available'}
          onLoad={() => setFallbackImageLoaded(true)}
          animation-duration={animationDuration}
          transition={disableTransition ? 0 : 1}
          transition-in={!disableTransition && fallbackImageLoaded ? 1 : 0}
        />
      ) : (
        errorFallback
      )}
    </>
  );
};

ConstructedErrorImage.defaultProps = {
  errorFallback: undefined,
  animationDuration: 1000,
  disableTransition: false,
};
