import { SxProps } from '@mui/material';

export const menuHoursWrapper: SxProps = {
  border: '1px solid rgb(231, 231, 231)',
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 8px',
  borderRadius: '8px',
  width: '900px',
};

export const dayText: SxProps = {
  display: 'flex',
  alignItems: 'center',
  width: '100px',
  minWidth: '100px',
  marginRight: '20px',
  '& h6': {
    color: '#191919',
    fontWeight: 500,
  },
};

export const hourSelect: SxProps = {
  width: '125px',
  height: '32px',
  borderRadius: '8px',
  backgroundColor: 'rgb(247, 247, 247)',
  border: 'none !important',
  display: 'flex',
  padding: '0 8px',
  alignItems: 'center',
  '&.MuiInputBase-root': {
    '&.Mui-disabled': {
      '::before': {
        borderBottomStyle: 'none !important',
      },
    },
  },
  '::before': {
    borderBottom: 'none',
  },
  'div:first-child': {
    padding: 0,
  },
  ':hover': {
    '::before': {
      borderBottom: 'none !important',
    },
  },
};

export const checkBox: SxProps = {
  display: 'flex',
  gap: 0.5,
  alignItems: 'center',
  '& input': {
    height: '18px',
    width: '18px',
  },
};
