import { Status } from 'enums/status';
import { StoreFinderResponse } from 'api/stores/types';
import { createSlice } from '@reduxjs/toolkit';
import { getAllStores } from 'redux/stores/stores.action';

export type StoresState = {
  data: StoreFinderResponse;
  status: Status;
};

const initialState: StoresState = {
  data: {
    storeData: [],
  },
  status: Status.IDLE,
};

const slice = createSlice({
  name: 'stores',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllStores.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = Status.SUCCESS;
      })
      .addCase(getAllStores.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addCase(getAllStores.pending, (state) => {
        state.status = Status.IN_PROGRESS;
      })
      .addDefaultCase((state) => state);
  },
});

export const storesReducer = slice.reducer;
