import { Backdrop } from 'components/common/Backdrop/Backdrop';
import { Box, Button } from '@mui/material';
import { DialogBox } from 'components/common/DialogBox/DialogBox';
import { ModifierForm } from 'components/MenuModifiers/MenuForm/ModifierForm';
import { Status } from 'enums/status';
import { getErrorMessage } from 'redux/menuCreation/menuCreation.selectors';
import {
  modifierSaveStatus,
  selectModifierData,
} from 'redux/modifiers/modifiers.selectors';
import { saveMenuModifiers } from 'redux/modifiers/modifiers.action';
import { schema } from 'components/MenuModifiers/schema';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';

export const MenuModifiers: FC = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const modifierData = useSelector(selectModifierData);
  const isProcessing = useSelector(modifierSaveStatus) === Status.IN_PROGRESS;
  const modifierUpdated = useSelector(modifierSaveStatus) === Status.SUCCESS;

  const apiErrorMessage = useSelector(getErrorMessage);
  useEffect(() => {
    setErrorMessage(apiErrorMessage);
  }, [apiErrorMessage]);

  const saveModifiers = () => {
    schema
      .validate(modifierData)
      .then((x) => {
        if (x) {
          dispatch(saveMenuModifiers(modifierData));
        }
      })
      .catch((e) => {
        setErrorMessage((e as Error).message);
      });
  };

  return (
    <>
      <DialogBox
        opened={modifierUpdated}
        message="Edible DoorDash Modifier Saved Successfully!"
      />
      <Backdrop opened={isProcessing} />
      <Box display="flex" flexDirection="column" margin="25px auto">
        <ModifierForm errorMessage={errorMessage} />
        <Button
          variant="contained"
          onClick={() => saveModifiers()}
          style={{ margin: '0 auto', width: 300 }}
        >
          Save Modifiers
        </Button>
      </Box>
    </>
  );
};
