import { MenuManager } from 'components/MenuManager/MenuManager';
import { allStores } from 'redux/stores/stores.selectors';
import { useFetchEdibleStores } from 'containers/StoresContainer/hooks/use-fetch-edible-stores';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

export const MenuManagerContainer: FC = () => {
  useFetchEdibleStores();
  const allEdibleStores = useSelector(allStores);

  return <MenuManager stores={allEdibleStores} />;
};

// eslint-disable-next-line import/no-default-export
export default MenuManagerContainer;
