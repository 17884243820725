import { DefaultSuspense } from 'components/common/DefaultSuspense/DefaultSuspense';
import { MenuCreationContainer } from 'containers/MenuCreationContainer/MenuCreationContainer';
import { MenuManagerContainer } from 'containers/MenuManagerContainer/MenuManagerContainer';
import { MenuModifiersContainer } from 'containers/MenuModifiersContainer/MenuModifiersContainer';
import { Route, Routes } from 'react-router-dom';
import { StoreMenuCreationContainer } from 'containers/StoreMenuCreationContainer/StoreMenuCreationContainer';
import { StoresContainer } from 'containers/StoresContainer/StoresContainer';
import { ThemeProvider } from '@mui/material';
import { getTheme } from 'utils/theme/get-theme';
import React, { FC } from 'react';

interface Props {
  isSuperAdmin: boolean;
}

export const MainContainer: FC<Props> = ({ isSuperAdmin }) => (
  <ThemeProvider theme={getTheme()}>
    <Routes>
      <Route
        path="/door-dash/stores"
        element={
          <DefaultSuspense>
            <StoresContainer />
          </DefaultSuspense>
        }
      />
      <Route
        path="/door-dash/menu-manager"
        element={
          <DefaultSuspense>
            <MenuManagerContainer />
          </DefaultSuspense>
        }
      />
      {isSuperAdmin && (
        <Route
          path="/door-dash/menu-creation"
          element={
            <DefaultSuspense>
              <MenuCreationContainer />
            </DefaultSuspense>
          }
        />
      )}
      {isSuperAdmin && (
        <Route
          path="/door-dash/menu-modifiers"
          element={
            <DefaultSuspense>
              <MenuModifiersContainer />
            </DefaultSuspense>
          }
        />
      )}
      <Route
        path="/door-dash/store-menu-creation/:StoreNumber"
        element={
          <DefaultSuspense>
            <StoreMenuCreationContainer />
          </DefaultSuspense>
        }
      />
    </Routes>
  </ThemeProvider>
);
