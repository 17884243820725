/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-array-index-key */
import * as styles from 'components/MenuCreation/MenuForm/CategoryCreation/styles';
import { AdditionalModifierOption } from 'components/MenuModifiers/MenuForm/AdditionalModifierOption/AdditionalModifierOption';
import {
  Box,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { CurrentMenuModifiers } from 'api/modifiers/types';
import { ModifierOption } from 'components/MenuModifiers/MenuForm/ModifierOptions/ModifierOptions';
import { Option } from 'types/modifier-options.type';
import { ProductSelection } from 'components/MenuModifiers/MenuForm/ProductSelection/ProductSelection';
import { menuItems } from 'redux/menuCreation/menuCreation.selectors';
import { setModifierData } from 'redux/modifiers/modifiers.slice';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  modifier: CurrentMenuModifiers;
}

interface OptionType {
  name: string;
  sku: string;
  img: string;
}

export const ModifierCreation: FC<Props> = ({ modifier }) => {
  const dispatch = useDispatch();
  const allMenuItems = useSelector(menuItems);
  const [optionCount, setOptionCount] = useState<number>(1);
  const [selectedItems, setSelectedItems] = useState<OptionType[]>([]);
  const [modifierOptions, setModifierOptions] = useState<Option[]>([]);
  const [modifierToAdd, setModifierToAdd] =
    useState<CurrentMenuModifiers>(modifier);

  useEffect(() => {
    if (modifier.options.length > 0) {
      setOptionCount(modifier.options.length);
    }
  }, [modifier]);

  useEffect(() => {
    setModifierOptions(modifier.options);
  }, []);

  useEffect(() => {
    setModifierToAdd((prevForm) => ({
      ...prevForm,
      options: modifierOptions,
    }));
  }, [modifierOptions]);

  useEffect(() => {
    dispatch(setModifierData(modifierToAdd));
  }, [modifierToAdd]);

  useEffect(() => {
    if (allMenuItems && selectedItems.length === 0) {
      const itemsToSet = allMenuItems
        .filter((item) => {
          if (item?.sku) {
            return modifier.sku.includes(item.sku);
          }
          return [];
        })
        .map((item) => ({
          name: item?.name || '',
          sku: item?.sku || '',
          img: item?.original_image_url || '',
        }));
      setSelectedItems(itemsToSet);
    }
  }, [allMenuItems]);

  const updateSelectedOption = (option: Option, originalOption?: string) => {
    const modOptionsToSet = modifierOptions.filter(
      (modOption) => modOption.merchant_supplied_id !== originalOption,
    );

    modOptionsToSet.push(option);
    setModifierOptions(modOptionsToSet);
    setModifierToAdd((prevForm) => ({
      ...prevForm,
      options: modOptionsToSet,
    }));
  };

  const updateOption = (productId: string, updatedValues: Partial<Option>) => {
    setModifierOptions((prevItems) =>
      prevItems.map((item) =>
        item.merchant_supplied_id === productId
          ? { ...item, ...updatedValues }
          : item,
      ),
    );
  };

  const updateOptionCount = () => {
    setOptionCount(optionCount + 1);
  };

  const updateModifier = (updatedValues: Partial<CurrentMenuModifiers>) => {
    setModifierToAdd((prevForm) => ({
      ...prevForm,
      ...updatedValues,
    }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    enabled?: boolean,
  ) => {
    const { name, value } = event.target;

    if (name === 'name') {
      updateModifier({ merchant_supplied_id: value });
    }
    if (enabled !== undefined) {
      updateModifier({ [name]: enabled });
    } else {
      updateModifier({ [name]: value });
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    const { name, value } = event.target;
    updateModifier({ [name]: value });
  };

  const handleSelectionChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: OptionType[],
  ) => {
    setSelectedItems(newValue);
    setModifierToAdd((prevForm) => ({
      ...prevForm,
      sku: newValue.map((item) => item.sku),
    }));
  };

  const optionsToDisplay = () => {
    const components = [];
    for (let i = 0; i < optionCount; i += 1) {
      components.push(
        modifierOptions[i] ? (
          <ModifierOption
            key={modifierOptions[i].merchant_supplied_id}
            modOption={modifierOptions[i]}
            updateSelectedOption={updateSelectedOption}
            updateOption={updateOption}
          />
        ) : (
          <ModifierOption
            key={i}
            modOption={{
              merchant_supplied_id: '',
              name: '',
              active: true,
              original_image_url: '',
              price: 0,
              base_price: 0,
            }}
            updateSelectedOption={updateSelectedOption}
            updateOption={updateOption}
          />
        ),
      );
    }
    return components;
  };

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h3" pb={1} color="#191919">
        Modifier
      </Typography>
      <Box ml={3} mb={3}>
        <Typography variant="body3" color="#191919">
          Lets create a MENU modifier by providing a name ie. Add-Ons, Flavor,
          Size Option, Quantity Choice, etc.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-around">
        <TextField
          onChange={handleInputChange}
          placeholder="Name"
          name="name"
          label="Name"
          variant="standard"
          required
          sx={{
            width: '300px',
            '& input': {
              textAlign: 'center',
            },
          }}
          value={modifierToAdd.name}
        />
      </Box>
      <Box display="flex" justifyContent="space-around" mt={5}>
        <FormControl sx={{ width: '250px' }}>
          <InputLabel id="minimum-amount-options-label">
            Min Amount of Options
          </InputLabel>
          <Select
            labelId="minimum-amount-options-label"
            id="minimum-amount-options-select"
            name="min_num_options"
            value={modifierToAdd.min_num_options}
            label="Min Amount Options"
            onChange={handleSelectChange}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: '250px' }}>
          <InputLabel id="maximum-amount-options-label">
            Max Amount of Options
          </InputLabel>
          <Select
            labelId="maximum-amount-options-label"
            id="maximum-amount-options-select"
            name="max_num_options"
            value={modifierToAdd.max_num_options}
            label="Max Amount Options"
            onChange={handleSelectChange}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Typography variant="h3" pb={1} color="#191919" mt={8}>
        Items
      </Typography>
      <Box ml={3} mb={6}>
        <Typography variant="body3" color="#191919">
          Next, add products in which you&apos;d like the modifier to be
          associated with.
        </Typography>
      </Box>
      <ProductSelection
        selectedItems={selectedItems}
        handleSelectionChange={handleSelectionChange}
      />
      <Typography variant="h3" pb={1} color="#191919" mt={8}>
        Options
      </Typography>
      <Box ml={3} mb={6}>
        <Typography variant="body3" color="#191919">
          Finally, add relevant products to your modifier by first selecting a
          product to add from the dropdown and editing it as you wish.
        </Typography>
      </Box>
      {optionsToDisplay()}
      <AdditionalModifierOption onClick={updateOptionCount} />
    </Box>
  );
};
