import { AxiosError } from 'axios';
import { CreateStoreMenuRequest } from 'types/menu.type';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { menuService } from 'api/menu/menu.api.service';
import { parseAxiosError } from 'utils/errors';

export const getDoorDashStoreMenu = createAsyncThunk(
  'doordash/menu/getStoreMenu',
  async (storeNumber: string, { rejectWithValue }) => {
    try {
      const storeMenu = await menuService.getStoreMenu(storeNumber);
      return storeMenu;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const updateDoorDashStoreMenu = createAsyncThunk(
  'doordash/menu/update',
  async (menuParams: CreateStoreMenuRequest, { rejectWithValue }) => {
    try {
      const storeMenu = await menuService.updateMenu(menuParams);
      return storeMenu;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(parseAxiosError(error));
    }
  },
);
