import * as yup from 'yup';

export const schema = yup.array(
  yup.object({
    id: yup.string().notRequired(),
    sku: yup.array().notRequired(),
    name: yup.string().required('Modifier Name is Required'),
    merchant_supplied_id: yup
      .string()
      .required('merchant_supplied_id is Required'),
    min_num_options: yup.number().required('min_num_options is Required'),
    max_num_options: yup.number().required('max_num_options is Required'),
    options: yup.array(
      yup.object({
        active: yup.boolean().required(),
        base_price: yup.number().required('Modifier Option Price Required'),
        merchant_supplied_id: yup
          .string()
          .required('merchant_supplied_id is Required'),
        name: yup.string().required('Modifier Option Name Required'),
        original_image_url: yup
          .string()
          .required('Modifier Option Image Required'),
        price: yup.number().required('Modifier Option Price Required'),
      }),
    ),
  }),
);
