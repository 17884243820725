import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { StoreFinderWidget } from 'api/stores/types';
import { navigate } from 'utils/navigate';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface Props {
  stores: StoreFinderWidget[];
}

function createData(storeLocation: string, storeNumber: number) {
  return { storeLocation, storeNumber };
}

export const StoreTable: FC<Props> = ({ stores }) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage] = useState<number>(20);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const startIndex = page * rowsPerPage + 1;
  const endIndex = Math.min((page + 1) * rowsPerPage, stores.length);
  const totalRows = stores.length;

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortedStores = [...stores];
  sortedStores.sort((a, b) => {
    if (sortOrder === 'asc') {
      return Number(a.storeNumber) - Number(b.storeNumber);
    }
    return Number(b.storeNumber) - Number(a.storeNumber);
  });

  const rows = sortedStores
    .slice(startIndex - 1, endIndex)
    .map((store) => createData(store.address, Number(store.storeNumber)));

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Store</TableCell>
              <TableCell
                align="right"
                onClick={handleSort}
                sx={{ cursor: 'pointer' }}
              >
                Number
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.storeLocation}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() =>
                  navigate(`/door-dash/store-menu-creation/${row.storeNumber}`)
                }
              >
                <TableCell component="th" scope="row">
                  {row.storeLocation.replace(/<br>/g, ',')}
                </TableCell>
                <TableCell align="right">{row.storeNumber}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Typography>
          Showing {startIndex}-{endIndex} of {totalRows}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
        <Button
          variant="outlined"
          disabled={page === 0}
          onClick={() => handleChangePage(page - 1)}
        >
          Previous Page
        </Button>
        <Button
          variant="outlined"
          disabled={endIndex >= stores.length}
          onClick={() => handleChangePage(page + 1)}
        >
          Next Page
        </Button>
      </Box>
    </>
  );
};
