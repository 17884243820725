import { Autocomplete, Box, FormControl, TextField } from '@mui/material';
import { AvailableDoorDashModifiers } from 'api/modifiers/types';
import { Option } from 'types/modifier-options.type';
import { sanitize } from 'dompurify';
import React, { FC } from 'react';

interface Props {
  availableModifierOptions: AvailableDoorDashModifiers[];
  selectedModifierOption: Option;
  onSelection: (productId: number) => void;
}

export const ModifierSelection: FC<Props> = ({
  availableModifierOptions,
  selectedModifierOption,
  onSelection,
}) => {
  return (
    <FormControl
      style={{ width: '400px', display: 'flex', margin: '0 auto' }}
      variant="filled"
    >
      <Autocomplete
        options={availableModifierOptions}
        value={availableModifierOptions.find(
          (x) =>
            x.productId === Number(selectedModifierOption.merchant_supplied_id),
        )}
        id="product-select"
        onChange={(e, option) => onSelection(Number(option?.productId))}
        sx={{ width: 355, height: 70 }}
        getOptionLabel={(option) => option.addonOptionName}
        renderOption={(props, option) => (
          <Box key={option.productId} component="li" {...props}>
            {sanitize(option.addonOptionName)}
          </Box>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Options" />
        )}
      />
    </FormControl>
  );
};
