import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { storesService } from 'api/stores/stores.api.service';

export const getAllStores = createAsyncThunk(
  'stores/getAllStores',
  async (_, { rejectWithValue }) => {
    try {
      const storesData = await storesService.getAllStores();
      return storesData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(parseAxiosError(error));
    }
  },
);
