import {
  AvailableDoorDashModifiers,
  CurrentMenuModifiers,
} from 'api/modifiers/types';
import { getPlatformAPIUrl } from 'utils/platform-api-url';
import { getTPAPIKey, getTPAPIUrl } from 'utils/tp-api-url';
import axios from 'axios';

class ModifierApiService {
  async getAvailableMenuModifiers(): Promise<AvailableDoorDashModifiers[]> {
    const url = getPlatformAPIUrl();
    const response = await axios.get<AvailableDoorDashModifiers[]>(
      `${url}/products/affiliate-addons`,
    );

    return response.data;
  }

  async getCurrentMenuModifiers(): Promise<CurrentMenuModifiers[]> {
    const header = {
      'Ocp-Apim-Subscription-Key': getTPAPIKey(),
    };
    const response = await axios.get<CurrentMenuModifiers[]>(
      `${getTPAPIUrl()}/doordash/menu/getMenuModifiers`,
      { headers: header },
    );

    return response.data;
  }

  async saveMenuModifiers(
    data: CurrentMenuModifiers[],
  ): Promise<CurrentMenuModifiers[]> {
    const header = {
      'Ocp-Apim-Subscription-Key': getTPAPIKey(),
    };
    const response = await axios.post<CurrentMenuModifiers[]>(
      `${getTPAPIUrl()}/doordash/menu/addMenuModifiers`,
      data,
      { headers: header },
    );

    return response.data;
  }
}

const modifierService = new ModifierApiService();

export { modifierService };
